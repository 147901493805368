
.mol-header-container {
  background-color: #F9F9F9;
  border-bottom: 1px solid $gray-300;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .03);

  &.mol-header-checkout {
    border-bottom: 1px solid $gray-275;
  }
}

.mol-header-container-inner {
  background: linear-gradient(90deg, #F9F9F9, #F3F3F3 30%, #F3F3F3 70%, #F9F9F9);
}

/**
 icon buttons
 */

.mol-header-button-wrapper {
  cursor: pointer;
}

/* icons */

.mol-header-button-icon-wrapper {
  width: 35px;
  height: 35px;
  background-color: #FFF;
  border: 1px solid $gray-300;
  border-radius: 50%;

  &.mol-header-button-cce-search {
    background:
      linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 100%),
      url("../../images/mol/templ/cce_search_header_btn_bg.png") no-repeat center;
  }
}

@include media-breakpoint-up(sm) {
  .mol-header-button-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

@include media-breakpoint-up(md) {
  .mol-header-button-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

/* menu button */

// hover
@include media-breakpoint-up(md) {
  .mol-header-button-menu {
    &:hover {
      & > .mol-header-button-icon-wrapper {
        background-color: #656565; //$gray-200;
      }
    }
  }
}

// no background on mobile
.mol-header-button-menu {
  & > .mol-header-button-icon-wrapper {
    width: 22px;
    background: transparent;
    border: 1px solid transparent;


  }
}

@include media-breakpoint-up(md) {
  .mol-header-button-menu {
    & > .mol-header-button-icon-wrapper {
      //width: 40px;
      //background-color: #FFF;
      //border: 1px solid $mol-border-color-light;
      height:36px; width:36px;
      background-color:#858585;
      border:0;
    }
  }
}

/* search bar */
/*
.mol-search-button  {
  border: 1px solid #CED4DA !important;

  $color: $light;

  @if $enable-gradients {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  } @else {
    background-color: $color !important;
  }

  img {
    opacity: .8;
  }
}

.mol-search-button:hover  {
  img {
    opacity: 1;
  }

  $color: darken($light, 3%);

  @if $enable-gradients {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  } @else {
    background-color: $color !important;
  }
}
*/
