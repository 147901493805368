
.mol-footer-container {
  border-top: 1px solid #D9D9D9;
  background-color: #F8F8F8;
  box-shadow: 0 50vh 0 50vh #F8F8F8; // stretch the footer color till page bottom
}

.mol-footer-container-inner {
  background: linear-gradient(90deg, #F8F8F8, #F2F2F2 30%, #F2F2F2 70%, #F8F8F8);
}

.mol-footer-container.mol-checkout-footer {
  border-top: 1px solid #D9D9D9; //$gray-275;
  background-color: #F2F2F2;
  box-shadow: 0 50vh 0 50vh #F2F2F2; // stretch the footer color till page bottom
}

.mol-footer-row {
  color:#777;
  border-top: 1px solid $gray-300;
  font-size: $mol-smaller-font-size;
}

.mol-text-footer {
  color:#777;
  font-size: $mol-small-font-size;
}

.mol-footer-heading {
  //height:25px;
  color: #777;
  font-weight: $font-weight-bold;

  .mol-checkout-footer &,
  .mol-checkout-merchant-card & {
    color: #888;
  }
}

a.mol-footer-link {
  display: inline-block;
  margin-top: .4rem;
  color: #777;
  font-size: $mol-small-font-size;

  & > img {
    opacity: .3;
  }

  &:hover,
  &:focus {
    color: darken(#777, 15%);

    & > img {
      opacity: .6;
    }
  }

  .mol-checkout-footer &,
  .mol-checkout-merchant-card & {
    color: #8f8f8f;
  }
}

.mol-btn-scroll-to-top {

  margin-left: 25px; // mostly center the text

  & img {
    opacity: .4;
  }

  &:hover,
  &:focus {

    text-decoration: none;

    & img {
      opacity: .7;
    }
  }
}
.mol-btn-scroll-to-top-icon {
  height: 25px;
  width: 25px;
  margin-left:10px;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid $gray-300;
}
