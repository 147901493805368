/**
 * section boxes
 *
 * follows this structure:
 * - page-box (box around the complete main content)
 *  - pb-content-section
 *    - pb-content-section-inner
 *
 * section divider should be outside the content-section element
 */

// common standard section handling
// mol-p-b-
.mol-page-box {
  padding-bottom: 1rem;
}

.mol-p-b-content-section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mol-p-b-content-section-inner {
}

@include media-breakpoint-up(md) {
  .mol-p-b-content-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

// left menu section (turn into offcanvas at smaller devices)
.mol-col-left-menu-section {
  height: 0px !important;
  min-height: 0px !important;
}

.mol-p-b-content-section.mol-left-menu-section {
  margin-top: 0;
  margin-bottom: 0;
}

@include media-breakpoint-up(xl) {
  .mol-col-left-menu-section {
    position: static !important;
    min-height: 1px !important;
  }

  .mol-p-b-content-section.mol-left-menu-section {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: .5rem;
    //padding-right: .5rem;

    //padding-right: 1rem;
    padding-top: 1rem; //1.3rem;
    padding-left: 0px;
    padding-bottom: 0px;
    //height: 100%;
    //background-color: #FCFCFC;
    //background: linear-gradient(to top, #fff, transparent 50px), linear-gradient(to left, #FCFCFC, #fdfdfd 50%, #fff 100%);
    //border-right: 1px solid #ECECEC;
    //border-radius: $border-radius;

    /*position:relative;

    &:after {
      position:absolute;
      bottom: 0;
      right: -1px;
      height: 50px;
      width: 1px;
      content: " ";
      background: linear-gradient(to top, #fff, transparent 50px),
    }*/

  }
}

// bordered page-box on XL (e.g. product_info page)
// prefix: mol-p-b-xl-b-
.mol-page-box-xl-bordered {
  padding-bottom: .5rem;
}

@include media-breakpoint-up(xl) {
 .mol-page-box-xl-bordered {
   margin-top: 1.2rem;
   background-color: #FFF;
   border: 1px solid $gray-275; //$border-color; //#E5E5E5;
   //border-top: 1px solid $gray-150;
   //border-top: 2px solid $gray-250;
   border-bottom: 0;
   border-radius: $border-radius $border-radius 0 0;
   box-shadow: 0 5px 3px 2px rgba(0, 0, 0, 0.05); // 0 0 0 1px $gray-275
   //padding-bottom: 2.5rem;
 }
}
// content secition
.mol-p-b-xl-b-content-section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-up(md) {
  .mol-p-b-xl-b-content-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

/*
@include media-breakpoint-up(xl) {
  .mol-content-card {
    //margin-top: 2rem;
    //margin-bottom: 2rem;
    //border: 1px solid $border-color;
    //border-radius: $border-radius;
    //padding: 1.5rem;
    //background-color: $white;
    //box-shadow: 0 1px 3px rgba(0,0,0,.15);
    //box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  }
}
*/

// content section padding
@include media-breakpoint-up(xl) {
  .mol-p-b-xl-b-content-section-inner {
    padding: 0 3rem !important;
  }
}
