/**
 * display
 */

.mol-d-none {
  display: none !important;
}

.mol-d-list-item {
  display: list-item !important;
}

@include media-breakpoint-up(sm) {
  .mol-d-sm-list-item {
    display: list-item !important;
  }
}

@include media-breakpoint-up(md) {
  .mol-d-md-list-item {
    display: list-item !important;
  }
}

@include media-breakpoint-up(lg) {
  .mol-d-lg-list-item {
    display: list-item !important;
  }
}

@include media-breakpoint-up(xl) {
  .mol-d-xl-list-item {
    display: list-item !important;
  }
}

/**
 * backgrounds
 */
.mol-bg-150 {
  background-color: $gray-150 !important;
}

.mol-bg-100 {
  background-color: $gray-100 !important;
}

/**
 * tables
 */
.table {
  td {
    padding: .75rem $table-cell-padding !important;
  }

  &.table-borderless {
    margin-bottom: 0 !important;

    td {
      padding: .75rem $table-cell-padding 0 0 !important;
    }
    & tr:first-of-type {
      & td {
        padding: 0 $table-cell-padding 0 0 !important;
      }
    }
  }
}

/**
 * Badges
 */
.mol-badge-cart-qty {
  color: #FFF;
  background-color:#F1930D;
  //opacity: 1;
}

.mol-badge-category-p-qty {
  position:relative;
  //top:-5px;
  margin-left: .3rem;
  font-size: $mol-smaller-font-size !important;
  font-weight: $font-weight-normal !important;
  color: $secondary; //#FFF;
  background-color: transparent; //#858585;

  .mol-category-card-head & {
    font-weight: $font-weight-semi-bold !important;
  }

  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
}

.mol-badge-light {
    color: $text-muted !important;
}


/**
 * preloader
 */
.mol-preloader-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //width: 100%;
  //height: 100%;
  background-color: rgba(255, 255, 255, .8);
  z-index: 1051;
}

.mol-modal-preloader {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  z-index: 1051;
  background-color: rgba(255, 255, 255, .8);
}

.mol-preloader-img {
  opacity: .8;
  width: 60px;
  height: auto;
}

/**
 * marks
 */
.mol-relative-offer-price-mark {
  display: inline;
  font-size: inherit;
  color: inherit;
  vertical-align: super;
  font-size: 66%;

  &::after {
    content: '1';
  }
}

.mol-required-mark {
  display: inline;
  font-size: inherit;
  color: inherit;
  font-weight: normal;

  &::after {
    content: '*';
  }
}

/**
 * sizing
 */
.mol-width-xs-100 {
  width: 100% !important;
}

@include media-breakpoint-up(sm) {
  .mol-width-xs-100 {
   width: auto !important;
 }
}

/**
 * cols
 */

 // full width at xs (no y padding)
 .mol-col-no-gutters-xs {
   padding-left: 0 !important;
   padding-right: 0 !important;
 }

 @include media-breakpoint-up(sm) {
   .mol-col-no-gutters-xs {
     padding-left: 15px !important;
     padding-right: 15px !important;
   }
 }

 .mol-col-no-gutters-till-xl {
   padding-left: 0 !important;
   padding-right: 0 !important;
 }

 @include media-breakpoint-up(xl) {
   .mol-col-no-gutters-till-xl {
     padding-left: 15px !important;
     padding-right: 15px !important;
   }
 }

.mol-col-7-cols {
    flex: 0 0 14.285714% !important;
    max-width: 14.285714% !important;
}

 @include media-breakpoint-up(lg) {

   .mol-col-lg-5-cols {
       flex: 0 0 20% !important;
       max-width: 20% !important;
   }

  .mol-col-lg-7-cols {
      flex: 0 0 14.285714% !important;
      max-width: 14.285714% !important;
  }
}

/**
 * Misc
 */
.mol-shop-logo {
  max-width: 100px;
  max-height: 100px;
}

@import "common/_font.scss";
@import "common/_breadcrumb.scss";
@import "common/_button_link.scss";
@import "common/_category_card.scss";
@import "common/_divider.scss";
@import "common/_filter.scss";
@import "common/_form.scss";
@import "common/_modal.scss";
@import "common/_nav_menu.scss";
@import "common/_offcanvas.scss";
@import "common/_pagination.scss";
@import "common/_popover_dropdown.scss";
@import "common/_prouct_card.scss";
@import "common/_section_box.scss";
@import "common/_topics.scss";

@import "misc/_shop_info_modal.scss";

@import "page/_header.scss";
@import "page/_footer.scss";

//@import "page/_index.scss";
//@import "page/_checkout.scss";
//@import "page/shop/_product_info.scss";
//@import "page/shop/_index.scss";
