/**
 * divider
 */
.hr-light {
  border-top: 1px solid $gray-250 !important;
}

.hr-bold {
  border-top: 3px solid $hr-border-color !important;
}

.mol-section-divider {
  height: 1px;
  border-top: 1px solid $gray-250; //$hr-border-color;
  margin:0;
  padding:0;
}

.mol-section-divider-dark {
  height: 1px;
  border-top: 1px solid $gray-300; //$hr-border-color;
  margin:0;
  padding:0;
}

@include media-breakpoint-up(xl) {
  .mol-section-divider, .mol-section-divider-dark {
    .mol-page-box-xl-bordered & {
      margin:0 3rem;
    }
  }

  /*.mol-section-divider-light {
    margin:0 3rem;
  }*/
}

.mol-section-divider-blur-wrapper {
  position: relative;
  height: 1px;
}

.mol-section-divider-blur {
  position: absolute;
  top: 0px;
  width: 100%;
  border-top:1px solid #DFDFDF;
  background-color: transparent;
  background: linear-gradient(180deg, #f6f6f6 ,#FFFFFF 40px) no-repeat;
  height:3rem;
  //margin-top:1.5rem;
  //margin-bottom:1.55rem;

  & > .mol-section-divider-blur-overlay {
    position: absolute;
    top: -1px;
    width: 100%;
    height: 3.05rem;
    background-color: transparent;
    background: linear-gradient(90deg, #FFFFFF, rgba(255,255,255,0), #FFFFFF) no-repeat;
  }
}

.mol-section-divider-blur-content {
  position: relative;
  padding-top: 2rem;
  //padding-bottom: 1.5rem;

}
