/**
 * Breadcrumb
 */
.mol-breadcrumb-container-outer {
  // fluid outer container for styled breadcrumbs
  padding-top:3px;
  //border-bottom: 1px solid #F3F3F3;
  //background-color:#FEFEFE;
  //background: linear-gradient(180deg, #F4F4F4, #FEFEFE 3px, #FEFEFE);

  @include media-breakpoint-up(xl) {
    padding-top:0px;
    //border-bottom: 1px solid #F3F3F3;
  }

  & .mol-breadcrumb-container {
    //background: linear-gradient(90deg, #FEFEFE, #FDFDFD 30%, #FDFDFD 70%, #FEFEFE);
    //padding-top: .8rem;
    //padding-bottom: .8rem;
  }
}

.mol-breadcrumb-container-outer.mol-product-page-breadcrumb {
  border-bottom: 1px solid $gray-200;

  @include media-breakpoint-up(md) {
    border-bottom: 0;
  }
}

.mol-breadcrumb-container {
  //padding-top: 1rem;
  padding-top: .8rem;
  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    //padding-bottom: .25rem;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 1rem;
    //padding-bottom: 1rem;
    //border-bottom: 1px solid #F3F3F3;
    //padding-bottom: .5rem;
  }
}

.mol-product-page-breadcrumb .mol-breadcrumb-container {
  padding-top: .5rem;
  padding-bottom: .6rem;

  @include media-breakpoint-up(md) {
    padding-top: 1rem;
    padding-bottom: .6rem;
    border-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 1rem;
    padding-bottom: .1rem;
  }
}

.breadcrumb {
  padding: 0 !important;
}

.breadcrumb-item {

  font-size: $mol-xs-font-size !important; //$small-font-size !important;
  text-decoration: underline;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888; //$gray-600; //#888;

  &:first-of-type {
    max-width: 90px;
  }

  & > a {
    color: #888;

    &:hover {
      color: darken(#777, 15%);
    }
  }

  .mol-breadcrumb-side-bar > & {
    max-width: 130px;

    &:first-of-type {
      max-width: 90px;
    }
  }

  // divider
  + .breadcrumb-item {

    padding-left: 0 !important;
    margin-left: -0.5rem;

    &:not(.mol-xs-first-item){

      margin-left: 0;

      &::before {

        background-image: url('../../images/mol/templ/icon/ic_arrow_right_dark.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 4px 8px;
        width: 4px;
        height: 8px;
        margin-left: .5rem;
        margin-right: .5rem;
        opacity: .5;
      }
    }

    @include media-breakpoint-up(sm) {
      &:not(.mol-sm-first-item){

        margin-left: 0;

        &::before {

          background-image: url('../../images/mol/templ/icon/ic_arrow_right_dark.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 4px 8px;
          width: 4px;
          height: 8px;
          margin-left: .5rem;
          margin-right: .5rem;
          opacity: .5;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &:not(.mol-md-first-item){

        margin-left: 0;

        &::before {

          background-image: url('../../images/mol/templ/icon/ic_arrow_right_dark.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 4px 8px;
          width: 4px;
          height: 8px;
          margin-left: .5rem;
          margin-right: .5rem;
          opacity: .5;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:not(.mol-lg-first-item){

        margin-left: 0;

        &::before {

          background-image: url('../../images/mol/templ/icon/ic_arrow_right_dark.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 4px 8px;
          width: 4px;
          height: 8px;
          margin-left: .5rem;
          margin-right: .5rem;
          opacity: .5;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &:not(.mol-xl-first-item){

        margin-left: 0;

        &::before {

          background-image: url('../../images/mol/templ/icon/ic_arrow_right_dark.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 4px 8px;
          width: 4px;
          height: 8px;
          margin-left: .4rem;
          margin-right: .4rem;
          opacity: .5;

          .mol-breadcrumb-side-bar & {
            margin-left: .5rem;
            margin-right: .5rem;
          }
        }
      }
    }
  }
}

.breadcrumb-item {
  .mol-product-page-breadcrumb & {
    text-decoration: none;

    /*& > a {
      color: $gray-600;

      &:hover {
        color: darken(#888, 15%);
      }
    }*/
  }
}

@include media-breakpoint-up(sm) {
  .breadcrumb-item {
    max-width: 150px;

    &:first-of-type {
      max-width: 140px;
    }
  }
}

@include media-breakpoint-up(md) {
  .breadcrumb-item {
    max-width: 170px;

    &:first-of-type {
      max-width: 160px;
    }

    .mol-product-page-breadcrumb & {
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-up(lg) {
  .breadcrumb-item {
    max-width: 170px;

    &:first-of-type {
      max-width: 160px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .breadcrumb-item {
    font-size: $mol-xs-font-size !important; //$mol-xs-font-size !important;
    text-decoration: none;
    color: #999;

    max-width: 150px;

    &:first-of-type {
      max-width: 140px;
    }

    & > a {
      color: #999;

      &:hover {
        color: darken(#888, 15%);
      }
    }

    .mol-product-page-breadcrumb & {

      max-width: 170px;

      &:first-of-type {
        max-width: 160px;
      }

      font-size: $mol-small-font-size !important;
      text-decoration: none;

      &:last-of-type {
        // product name
        font-weight: $font-weight-semi-bold;
        //color:#888;
        max-width: 200px;
      }
    }
  }
}

/* breacrumb menu buttons */
.mol-btn-breadcrumb-menu {
  //width: 28px;
  //height: 28px;
  height: 23px;
  width: 23px;
  padding:5px !important;
  border-radius: 50% !important;
  border: 0 !important;
  background-color: $gray-200;
  //border: 1px solid $gray-300 !important

  & img {
    opacity: .5;
    width: 100%;
  }

  &:hover {
    background-color: $gray-300;

    & img {
      opacity: .9;
    }
  }

}

/*@include media-breakpoint-up(lg) {
  .mol-btn-breadcrumb-menu {
    width: 26px;
    height: 26px;
  }
}*/

.mol-btn-breadcrumb-menu-dark {

  margin-right: 2rem;

  &:hover {
    & > .mol-btn-breadcrumb-menu-dark-icon-wrapper {
      background-color: #656565 !important; //$gray-200;
    }
  }
}

.mol-btn-breadcrumb-menu-dark-icon-wrapper {
  width: 28px;
  height: 28px;
  margin-right: .75rem;
  padding: 6px !important;
  background-color: #858585 !important;
  border-radius: 50% !important;
  border: 0 !important;

  & > img {
    width: 90%;
    opacity: .9;
  }

  //border: 1px solid $gray-300 !important
}

@include media-breakpoint-up(lg) {
  .mol-btn-breadcrumb-menu-dark-icon-wrapper {
    width: 28px;
    height: 28px;
  }
}

.mol-btn-breadcrumb-home {
  height: 14px; //24px;
  width: 14px; //24px;
  border: 1px solid #fff !important; //#F2F2F2 !important;
  background-color: #fff !important; // #FCFCFC !important;
  border-radius: 50% !important;
  margin-right: 1rem;
  padding: 0 !important; //5px !important;

  & > img  {
    height: 100%;
    opacity: .5;
  }
}
