/**
 * buttons & Links
 */

a.active {
  color: $link-hover-color;
  text-decoration: $link-hover-decoration;
}

.mol-btn-small {
  font-size: $mol-smaller-font-size;
}

.mol-btn-larger {
  padding: .7rem 2rem !important;
}

.mol-btn-xs {
  font-size: $mol-small-font-size !important;
  padding: .2rem .5rem !important;
}

.btn-primary {
  $color: $mol-btn-primary;

  //border: 1px solid darken($color, 5%) !important;
  background: $color linear-gradient(360deg, darken($color, 3%), $color 30%) repeat-x !important;
  text-shadow: 0px 0px 2px darken($color, 15%);

  &:hover,
  &.hover  {
    $color: darken($mol-btn-primary, 5%);
    background: $color linear-gradient(360deg, darken($color, 3%), $color 30%) repeat-x !important;
  }
}

.btn-light {
  border: 1px solid $gray-400 !important;
  color: $body-color !important;

  $color: $gray-200;

  @if $enable-gradients {
    background: $color linear-gradient(360deg, darken($color, 2%), $color 50%) repeat-x !important;
    //background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  } @else {
    background-color: $color !important;
  }

  img {
    opacity: .8;
  }

  &:hover,
  &.hover  {
    img {
      opacity: 1;
    }

    $color: darken($gray-200, 2%);

    @if $enable-gradients {
      background: $color linear-gradient(360deg, darken($color, 2%), $color 50%) repeat-x !important;
      //background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
    } @else {
      background-color: $color !important;
    }
  }

  &:focus,
  &.focus {
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($gray-600, .5) !important;
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba($gray-600, .5) !important;
    }
  }
}

.mol-btn-outline-secondary, .mol-btn-outline-secondary-2 {
  color: $body-color !important;
  background: $white !important;
  border: 1px solid $gray-400 !important;

  & > img {
    height: 13px;
    opacity: .8;
  }

  &.btn-sm {
    & > img {
      height: 11px;
    }
  }

  &:hover,
  &.hover {
    border: 1px solid $gray-500 !important;
    background-color: $gray-100 !important;

    & > img {
      opacity: 1;
    }
  }

  &:focus,
  &.focus  {
    border: 1px solid $gray-500 !important;
    background-color: $gray-200 !important;
    box-shadow: 0 0 0 1px $gray-200 !important;
  }
}

.mol-btn-outline-secondary-2 {
  // with bg gradient
  border: 1px solid $gray-300 !important;
  background: linear-gradient(360deg, #F3F3F3, #F9F9F9 30%, #F9F9F9 40%, #fff) !important;
  //background: linear-gradient(360deg, #F1F1F1, #F7F7F7 30%, #F7F7F7 40%, #fff) !important;

  &:hover,
  &.hover {
    border: 1px solid $gray-400 !important;
    background: linear-gradient(360deg, darken(#F3F3F3, 2%), darken(#F9F9F9, 2%) 30%, darken(#F9F9F9, 2%) 40%, #fdfdfd) !important;

    & > img {
      opacity: 1;
    }
  }

  &:focus,
  &.focus  {
    border: 1px solid $gray-400 !important;
    background: $gray-200 !important;
    box-shadow: 0 0 0 1px $gray-200 !important;
  }
}

.mol-btn-outline-tag {
  @extend .mol-btn-outline-secondary;

  color: $secondary !important;
  border-radius: .5rem !important;
}

.mol-btn-unstyled {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;

  img {
    height: 16px;
    opacity: .7;
  }

  &.mol-btn-small {
    img {
      height: 12px;
    }
  }

  &:hover,
  &.hover  {
    //background-color: rgba(255, 255, 255, 0.8) !important;
    //border:1px solid $gray-400 !important;

    img {
      opacity: 1;
    }
  }

}

.mol-btn-unstyled:focus, .mol-btn-unstyled.focus {
  box-shadow: none !important;
}

.mol-btn-close {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border:1px solid #FFF !important;
  border-radius:50% !important;
  z-index: 1052;

  img {
    height: 17px;
    opacity: .7;
  }

  &:hover,
  &.hover  {
    background-color: rgba(255, 255, 255, 0.8) !important;
    border:1px solid $gray-400 !important;

    img {
      opacity: 1;
    }
  }
}

.mol-btn-round-icon {
  width: 23px;
  height: 23px;
  padding: 4px !important;
  border-radius: 50% !important;
  border: 0 !important
}

.mol-img-link {
  text-decoration: none !important;

  & img {border: 0 !important;}
}

.mol-btn-checkbox-imitation, .mol-btn-checkbox-imitation-deactivated {
  height:17px;
  width:17px;
  padding: 0 !important;
  color: $body-color !important;
  background: $white !important;
  border: 1px solid $gray-500 !important;

  & > img {
    height: 9px;
    width: auto;
    opacity: .8;
  }

  &:hover,
  &.hover {
    border: 1px solid $gray-600 !important;
    background-color: $gray-100 !important;

    & > img {
      opacity: 1;
    }
  }

  &:focus,
  &.focus  {
    border: 1px solid $gray-600 !important;
    background-color: $gray-200 !important;
    box-shadow: 0 0 0 1px $gray-200 !important;
  }
}

.mol-btn-checkbox-imitation-deactivated {
  background-color: $gray-200 !important;
  cursor: default !important;

  &:hover,
  &:focus {
    border: 1px solid $gray-500 !important;
    background-color: $gray-200 !important;
  }
}

// Merchant Payment buttons
.mol-merchant-card-mop-thumb-buttons-container {
  margin-left: -15px;
}

.mol-merchant-card-mop-thumb-button {
  margin-top: 6px;
  margin-left: 15px;
  //background-color:#FFF;
  border-radius: $border-radius;
  //border: 1px solid $mol-border-color-light;
  height: 25px;
  //padding: 0 4px;

  & > img {
      height: 17px;
  }
}

.mol-mop-invoive-thumb {
  //margin-left: 1px;
  line-height: 1;
  height: 17px;
  color: #FFF;
  background-color: #7DB560;
  border-radius: .2rem;
  font-size: 11px;
  padding: 5px;
}

.clickableImage {
  cursor: pointer;
}

// brand Links
.mol-brand-link {

  color: $secondary !important;

  &:hover {
    color: darken($secondary, 15%) !important;
    text-decoration: none !important;

    &  .mol-brand-link-name {
      text-decoration: underline !important;
    }
  }
}

.mol-brand-name-desc {
  font-size: $small-font-size;
  color: $text-muted;
  //margin-top: .5em;
}

// nav link separater
.mol-link-separater {
  color: $gray-400;
}
