/**
 * Popovers
 */
.popover {
  border: 1px solid $border-color !important;
  box-shadow: 0 .1rem .4rem rgba($black, .15) !important;
}

/**
 * Dropdowns
 */
.dropdown-menu {
  box-shadow: 0 .1rem .4rem rgba($black, .15) !important;
}

.dropdown-header {
  position: relative;
  top: -$dropdown-padding-y;
  padding: .9rem $dropdown-item-padding-x !important;
  font-size: 1rem !important;
  font-weight: $font-weight-semi-bold;
  color: $body-color !important;
  background-color: $gray-200;
}

// responsive allignments
@include media-breakpoint-up(sm) {
  .mol-dropdown-menu-sm-right {
    right: 0 !important;
    left: auto !important;
  }
}
