/**
 * forms
 */

// search bar
.mol-search-input-group {
    border: 1px solid $gray-400;
    border-radius: $input-border-radius;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .03);

    &:focus-within {
      //border: 1px solid $input-focus-border-color;
      //box-shadow: inset 0 3px 3px rgba($black, .5); //$input-box-shadow, $input-focus-box-shadow;
      border: 1px solid $gray-500;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .07);
    }
}

.mol-search-input {
  border: 1px solid $input-bg !important;
  padding: .5rem $input-padding-x !important;
  height:auto !important;

  &.mol-cce-search-input {
    //background: linear-gradient(90deg, #F9FDFF, #FFF 50%);

    /*&::placeholder {
      color:#105074; //#B3CCDA;
      opacity: .5;
    }*/
  }

  &:focus {
    border: 1px solid $input-bg !important;
    //border-right: 1px solid $input-bg !important;
    //box-shadow: 20px 20px 50px 10px $black inset;
    box-shadow: none !important;
  }
}

.mol-btn-search {
  background-color: $input-bg !important; //$gray-100 !important;
  border: 1px solid $input-bg !important; //$gray-100 !important;
  border: 1px solid $input-bg !important;

  & > img {
    opacity: .7;
  }

  &:hover, &:focus {
    background-color: $gray-200 !important;
    border: 1px solid $gray-200 !important;
    box-shadow: none !important;

    & > img {
      opacity: 1;
    }
  }

  &.mol-btn-cce-search {
    //padding-right: 1rem !important;
    background:
      linear-gradient(90deg, #FFF, rgba(255, 255, 255, .9) 30%, rgba(255, 255, 255, .8) 60%, rgba(255, 255, 255, .1) 100%),
      url("../../images/mol/templ/cce_search_btn_bg.png") no-repeat right;

    & > img {
      opacity: .7;
    }

    &:hover, &:focus {
      border: 1px solid $input-bg !important;
      box-shadow: none !important;

      /*background:
        linear-gradient(90deg, #FFF, rgba(255, 255, 255, .9) 30%, rgba(255, 255, 255, .8) 60%, rgba(255, 255, 255, .1) 100%),
        url("../../images/mol/templ/cce_search_btn_bg.png") no-repeat right;
      */
      & > img {
        opacity: 1;
      }
    }
  }
}

// responsive textarea height
.mol-msg-textarea {
  height:7rem !important;
}

@include media-breakpoint-up(md) {
  .mol-msg-textarea {
    height:10rem !important;
  }
}

@include media-breakpoint-up(xl) {
  .mol-msg-textarea {
    height:14rem !important;
  }
}

/**
 * Labels
 */
.col-form-label {

  &.mol-form-label,
  &.mol-form-label-for-optionals {
    font-size: $mol-smaller-font-size;
    font-weight: $font-weight-semi-bold;

    & .mol-form-optional-mark {
      font-size: $mol-xs-font-size;
      color: $text-muted;
      font-weight: normal;
    }
  }
}

@include media-breakpoint-up(md) {

	.col-form-label {

		&.mol-form-label {
			font-size: 1rem;
		}

		&.mol-form-label-for-optionals {
      font-size: 1rem;
      padding-bottom: 0 !important;

      &.mol-form-label-dob {
        line-height: 1 !important;

        & .mol-form-optional-mark {
          line-height: 1;
        }
      }

		}
  }
}

/**
 * Misc
 */

// address from container
@include media-breakpoint-up(md) {
  .mol-address-form-container {
    max-width: 500px;
  }
}
