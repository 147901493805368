/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* font */
/* color */
/* spacer */
/* border */
/* gradients */
/* Divider */
/* main container widths */
/* Forms */
/* Tables */
/* carousel */
/* Modal sizes */
/* Breadcrumb */
/* Popovers */
/* Tooltips */
/* Dropdowns */
/* Pagination */
/* Offcanvas */
/* cards */
/* alerts */
/**
 * display
 */
.mol-d-none {
  display: none !important;
}

.mol-d-list-item {
  display: list-item !important;
}

@media (min-width: 576px) {
  .mol-d-sm-list-item {
    display: list-item !important;
  }
}

@media (min-width: 768px) {
  .mol-d-md-list-item {
    display: list-item !important;
  }
}

@media (min-width: 992px) {
  .mol-d-lg-list-item {
    display: list-item !important;
  }
}

@media (min-width: 1200px) {
  .mol-d-xl-list-item {
    display: list-item !important;
  }
}

/**
 * backgrounds
 */
.mol-bg-150 {
  background-color: #F9F9F9 !important;
}

.mol-bg-100 {
  background-color: #FAFAFA !important;
}

/**
 * tables
 */
.table td {
  padding: 0.75rem 1rem !important;
}

.table.table-borderless {
  margin-bottom: 0 !important;
}

.table.table-borderless td {
  padding: 0.75rem 1rem 0 0 !important;
}

.table.table-borderless tr:first-of-type td {
  padding: 0 1rem 0 0 !important;
}

/**
 * Badges
 */
.mol-badge-cart-qty {
  color: #FFF;
  background-color: #F1930D;
}

.mol-badge-category-p-qty {
  position: relative;
  margin-left: .3rem;
  font-size: 0.929rem !important;
  font-weight: 400 !important;
  color: #666666;
  background-color: transparent;
}

.mol-category-card-head .mol-badge-category-p-qty {
  font-weight: 500 !important;
}

.mol-badge-category-p-qty:before {
  content: '(';
}

.mol-badge-category-p-qty:after {
  content: ')';
}

.mol-badge-light {
  color: #999999 !important;
}

/**
 * preloader
 */
.mol-preloader-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1051;
}

.mol-modal-preloader {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
  z-index: 1051;
  background-color: rgba(255, 255, 255, 0.8);
}

.mol-preloader-img {
  opacity: .8;
  width: 60px;
  height: auto;
}

/**
 * marks
 */
.mol-relative-offer-price-mark {
  display: inline;
  font-size: inherit;
  color: inherit;
  vertical-align: super;
  font-size: 66%;
}

.mol-relative-offer-price-mark::after {
  content: '1';
}

.mol-required-mark {
  display: inline;
  font-size: inherit;
  color: inherit;
  font-weight: normal;
}

.mol-required-mark::after {
  content: '*';
}

/**
 * sizing
 */
.mol-width-xs-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .mol-width-xs-100 {
    width: auto !important;
  }
}

/**
 * cols
 */
.mol-col-no-gutters-xs {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 576px) {
  .mol-col-no-gutters-xs {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.mol-col-no-gutters-till-xl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width: 1200px) {
  .mol-col-no-gutters-till-xl {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.mol-col-7-cols {
  flex: 0 0 14.285714% !important;
  max-width: 14.285714% !important;
}

@media (min-width: 992px) {
  .mol-col-lg-5-cols {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .mol-col-lg-7-cols {
    flex: 0 0 14.285714% !important;
    max-width: 14.285714% !important;
  }
}

/**
 * Misc
 */
.mol-shop-logo {
  max-width: 100px;
  max-height: 100px;
}

/**
 * fonts
 */
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v18-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-300.woff2") format("woff2"), url("../fonts/roboto-v18-latin-300.woff") format("woff"), url("../fonts/roboto-v18-latin-300.ttf") format("truetype"), url("../fonts/roboto-v18-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v18-latin-regular.woff") format("woff"), url("../fonts/roboto-v18-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v18-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v18-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-latin-500.woff") format("woff"), url("../fonts/roboto-v18-latin-500.ttf") format("truetype"), url("../fonts/roboto-v18-latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v18-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-700.woff2") format("woff2"), url("../fonts/roboto-v18-latin-700.woff") format("woff"), url("../fonts/roboto-v18-latin-700.ttf") format("truetype"), url("../fonts/roboto-v18-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}

html {
  font-size: 15px !important;
}

@media (min-width: 768px) {
  html {
    font-size: 14px !important;
  }
}

h1.mol-unstyled, h2.mol-unstyled, h3.mol-unstyled {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  display: inline;
}

h1.mol-unstyled-semi-bold, h2.mol-unstyled-semi-bold, h3.mol-unstyled-semi-bold {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  display: inline;
}

h1.mol-section-heading, h2.mol-section-heading, h3.mol-section-heading {
  margin-top: 0;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

h1.mol-product-listing-heading, h1.mol-category-listing-heading, h1.mol-sections-top-heading, h2.mol-product-listing-heading, h2.mol-category-listing-heading, h2.mol-sections-top-heading, h3.mol-product-listing-heading, h3.mol-category-listing-heading, h3.mol-sections-top-heading {
  font-size: 1.429rem !important;
  font-weight: normal !important;
  line-height: 1.4 !important;
}

h1.mol-category-heading, h2.mol-category-heading, h3.mol-category-heading {
  font-size: 1.429rem !important;
  font-weight: 500 !important;
  line-height: 1.4 !important;
}

h1.mol-category-heading-2, h2.mol-category-heading-2, h3.mol-category-heading-2 {
  color: #105074;
}

@media (min-width: 768px) {
  h1.mol-section-heading, h2.mol-section-heading {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
  h1, h2 {
    font-size: 1.143rem !important;
  }
  h1.mol-section-heading, h2.mol-section-heading {
    font-size: 1.143rem !important;
  }
  h1.mol-product-listing-heading, h1.mol-category-listing-heading, h1.mol-sections-top-heading, h2.mol-product-listing-heading, h2.mol-category-listing-heading, h2.mol-sections-top-heading {
    font-size: 1.429rem !important;
  }
  h1.mol-category-heading, h2.mol-category-heading {
    font-size: 1.143rem !important;
  }
}

.mol-category-listing-heading-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .mol-category-listing-heading-container {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}

.mol-product-listing-heading-container {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .mol-product-listing-heading-container {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/*@include media-breakpoint-up(md) {
  .mol-product-listing-heading-container {
    margin-top: 0;
    margin-bottom: 0;
    //border-bottom: 1px solid $mol-border-color-light;
  }
}*/
@media (min-width: 1200px) {
  .mol-product-listing-heading-container {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}

.mol-left-menu-section-heading {
  font-size: 1.071rem;
  max-width: 220px;
  line-height: 1.5;
  padding-top: 1.5rem !important;
  padding-bottom: 0 !important;
  margin-bottom: 1rem;
  color: #777 !important;
  /*& a.mol-shop-start-page {
    color: #666 !important;
    font-size: $mol-smaller-font-size;
    //font-weight: $font-weight-semi-bold;

    &:hover {
      text-decoration: none;
      color: darken(#666, 15%) !important;

      & .mol-text-hover-underline {
        text-decoration: underline;
      }
    }
  }*/
  /*&:not(.mol-offcanvas-sub-head) {
    margin-top: 2px;
  }*/
}

.mol-left-menu-section-heading a {
  color: #666 !important;
}

.mol-left-menu-section-heading a:hover {
  color: #404040;
}

b,
strong {
  font-weight: 500 !important;
}

.smaller-text {
  font-size: 0.929rem !important;
}

.small-text {
  font-size: 0.857rem !important;
}

.xs-text {
  font-size: 0.786rem !important;
}

.bit-larger-text {
  font-size: 1.071rem !important;
}

.larger-text {
  font-size: 1.143rem !important;
}

.large-text {
  font-size: 1.429rem !important;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.thin {
  font-weight: 300;
}

.mol-text-underline {
  text-decoration: underline;
}

.mol-text-dark-blue {
  color: #105074 !important;
}

.mol-text-blue-secondary {
  color: #166fa1 !important;
}

.mol-text-orange {
  color: #E47911;
}

a.mol-text-orange:hover {
  color: #b5600d;
}

@media (min-width: 768px) {
  .large-text-md {
    font-size: 1.429rem !important;
  }
}

/**
 * Breadcrumb
 */
.mol-breadcrumb-container-outer {
  padding-top: 3px;
}

@media (min-width: 1200px) {
  .mol-breadcrumb-container-outer {
    padding-top: 0px;
  }
}

.mol-breadcrumb-container-outer.mol-product-page-breadcrumb {
  border-bottom: 1px solid #F2F2F2;
}

@media (min-width: 768px) {
  .mol-breadcrumb-container-outer.mol-product-page-breadcrumb {
    border-bottom: 0;
  }
}

.mol-breadcrumb-container {
  padding-top: .8rem;
  padding-bottom: 0;
}

@media (min-width: 1200px) {
  .mol-breadcrumb-container {
    padding-top: 1rem;
  }
}

.mol-product-page-breadcrumb .mol-breadcrumb-container {
  padding-top: .5rem;
  padding-bottom: .6rem;
}

@media (min-width: 768px) {
  .mol-product-page-breadcrumb .mol-breadcrumb-container {
    padding-top: 1rem;
    padding-bottom: .6rem;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .mol-product-page-breadcrumb .mol-breadcrumb-container {
    padding-top: 1rem;
    padding-bottom: .1rem;
  }
}

.breadcrumb {
  padding: 0 !important;
}

.breadcrumb-item {
  font-size: 0.786rem !important;
  text-decoration: underline;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #888;
}

.breadcrumb-item:first-of-type {
  max-width: 90px;
}

.breadcrumb-item > a {
  color: #888;
}

.breadcrumb-item > a:hover {
  color: #515151;
}

.mol-breadcrumb-side-bar > .breadcrumb-item {
  max-width: 130px;
}

.mol-breadcrumb-side-bar > .breadcrumb-item:first-of-type {
  max-width: 90px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0 !important;
  margin-left: -0.5rem;
}

.breadcrumb-item + .breadcrumb-item:not(.mol-xs-first-item) {
  margin-left: 0;
}

.breadcrumb-item + .breadcrumb-item:not(.mol-xs-first-item)::before {
  background-image: url("../../images/mol/templ/icon/ic_arrow_right_dark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 4px 8px;
  width: 4px;
  height: 8px;
  margin-left: .5rem;
  margin-right: .5rem;
  opacity: .5;
}

@media (min-width: 576px) {
  .breadcrumb-item + .breadcrumb-item:not(.mol-sm-first-item) {
    margin-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item:not(.mol-sm-first-item)::before {
    background-image: url("../../images/mol/templ/icon/ic_arrow_right_dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 4px 8px;
    width: 4px;
    height: 8px;
    margin-left: .5rem;
    margin-right: .5rem;
    opacity: .5;
  }
}

@media (min-width: 768px) {
  .breadcrumb-item + .breadcrumb-item:not(.mol-md-first-item) {
    margin-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item:not(.mol-md-first-item)::before {
    background-image: url("../../images/mol/templ/icon/ic_arrow_right_dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 4px 8px;
    width: 4px;
    height: 8px;
    margin-left: .5rem;
    margin-right: .5rem;
    opacity: .5;
  }
}

@media (min-width: 992px) {
  .breadcrumb-item + .breadcrumb-item:not(.mol-lg-first-item) {
    margin-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item:not(.mol-lg-first-item)::before {
    background-image: url("../../images/mol/templ/icon/ic_arrow_right_dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 4px 8px;
    width: 4px;
    height: 8px;
    margin-left: .5rem;
    margin-right: .5rem;
    opacity: .5;
  }
}

@media (min-width: 1200px) {
  .breadcrumb-item + .breadcrumb-item:not(.mol-xl-first-item) {
    margin-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item:not(.mol-xl-first-item)::before {
    background-image: url("../../images/mol/templ/icon/ic_arrow_right_dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 4px 8px;
    width: 4px;
    height: 8px;
    margin-left: .4rem;
    margin-right: .4rem;
    opacity: .5;
  }
  .mol-breadcrumb-side-bar .breadcrumb-item + .breadcrumb-item:not(.mol-xl-first-item)::before {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.mol-product-page-breadcrumb .breadcrumb-item {
  text-decoration: none;
  /*& > a {
      color: $gray-600;

      &:hover {
        color: darken(#888, 15%);
      }
    }*/
}

@media (min-width: 576px) {
  .breadcrumb-item {
    max-width: 150px;
  }
  .breadcrumb-item:first-of-type {
    max-width: 140px;
  }
}

@media (min-width: 768px) {
  .breadcrumb-item {
    max-width: 170px;
  }
  .breadcrumb-item:first-of-type {
    max-width: 160px;
  }
  .mol-product-page-breadcrumb .breadcrumb-item {
    text-decoration: underline;
  }
}

@media (min-width: 992px) {
  .breadcrumb-item {
    max-width: 170px;
  }
  .breadcrumb-item:first-of-type {
    max-width: 160px;
  }
}

@media (min-width: 1200px) {
  .breadcrumb-item {
    font-size: 0.786rem !important;
    text-decoration: none;
    color: #999;
    max-width: 150px;
  }
  .breadcrumb-item:first-of-type {
    max-width: 140px;
  }
  .breadcrumb-item > a {
    color: #999;
  }
  .breadcrumb-item > a:hover {
    color: #626262;
  }
  .mol-product-page-breadcrumb .breadcrumb-item {
    max-width: 170px;
    font-size: 0.857rem !important;
    text-decoration: none;
  }
  .mol-product-page-breadcrumb .breadcrumb-item:first-of-type {
    max-width: 160px;
  }
  .mol-product-page-breadcrumb .breadcrumb-item:last-of-type {
    font-weight: 500;
    max-width: 200px;
  }
}

/* breacrumb menu buttons */
.mol-btn-breadcrumb-menu {
  height: 23px;
  width: 23px;
  padding: 5px !important;
  border-radius: 50% !important;
  border: 0 !important;
  background-color: #F2F2F2;
}

.mol-btn-breadcrumb-menu img {
  opacity: .5;
  width: 100%;
}

.mol-btn-breadcrumb-menu:hover {
  background-color: #E5E5E5;
}

.mol-btn-breadcrumb-menu:hover img {
  opacity: .9;
}

/*@include media-breakpoint-up(lg) {
  .mol-btn-breadcrumb-menu {
    width: 26px;
    height: 26px;
  }
}*/
.mol-btn-breadcrumb-menu-dark {
  margin-right: 2rem;
}

.mol-btn-breadcrumb-menu-dark:hover > .mol-btn-breadcrumb-menu-dark-icon-wrapper {
  background-color: #656565 !important;
}

.mol-btn-breadcrumb-menu-dark-icon-wrapper {
  width: 28px;
  height: 28px;
  margin-right: .75rem;
  padding: 6px !important;
  background-color: #858585 !important;
  border-radius: 50% !important;
  border: 0 !important;
}

.mol-btn-breadcrumb-menu-dark-icon-wrapper > img {
  width: 90%;
  opacity: .9;
}

@media (min-width: 992px) {
  .mol-btn-breadcrumb-menu-dark-icon-wrapper {
    width: 28px;
    height: 28px;
  }
}

.mol-btn-breadcrumb-home {
  height: 14px;
  width: 14px;
  border: 1px solid #fff !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  margin-right: 1rem;
  padding: 0 !important;
}

.mol-btn-breadcrumb-home > img {
  height: 100%;
  opacity: .5;
}

/**
 * buttons & Links
 */
a.active {
  color: #105074;
  text-decoration: underline;
}

.mol-btn-small {
  font-size: 0.929rem;
}

.mol-btn-larger {
  padding: .7rem 2rem !important;
}

.mol-btn-xs {
  font-size: 0.857rem !important;
  padding: .2rem .5rem !important;
}

.btn-primary {
  background: #197EB7 linear-gradient(360deg, #1775aa, #197EB7 30%) repeat-x !important;
  text-shadow: 0px 0px 2px #105074;
}

.btn-primary:hover, .btn-primary.hover {
  background: #166fa1 linear-gradient(360deg, #146593, #166fa1 30%) repeat-x !important;
}

.btn-light {
  border: 1px solid #DFDFDF !important;
  color: #262626 !important;
  background-color: #F2F2F2 !important;
}

.btn-light img {
  opacity: .8;
}

.btn-light:hover, .btn-light.hover {
  background-color: #ededed !important;
}

.btn-light:hover img, .btn-light.hover img {
  opacity: 1;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 1px rgba(153, 153, 153, 0.5) !important;
}

.mol-btn-outline-secondary, .mol-btn-outline-tag, .mol-btn-outline-secondary-2 {
  color: #262626 !important;
  background: #fff !important;
  border: 1px solid #DFDFDF !important;
}

.mol-btn-outline-secondary > img, .mol-btn-outline-tag > img, .mol-btn-outline-secondary-2 > img {
  height: 13px;
  opacity: .8;
}

.mol-btn-outline-secondary.btn-sm > img, .btn-sm.mol-btn-outline-tag > img, .btn-group-sm > .mol-btn-outline-tag.btn > img, .btn-group-sm > .mol-btn-outline-secondary.btn > img, .mol-btn-outline-secondary-2.btn-sm > img, .btn-group-sm > .mol-btn-outline-secondary-2.btn > img {
  height: 11px;
}

.mol-btn-outline-secondary:hover, .mol-btn-outline-tag:hover, .mol-btn-outline-secondary.hover, .hover.mol-btn-outline-tag, .mol-btn-outline-secondary-2:hover, .mol-btn-outline-secondary-2.hover {
  border: 1px solid #CCCCCC !important;
  background-color: #FAFAFA !important;
}

.mol-btn-outline-secondary:hover > img, .mol-btn-outline-tag:hover > img, .mol-btn-outline-secondary.hover > img, .hover.mol-btn-outline-tag > img, .mol-btn-outline-secondary-2:hover > img, .mol-btn-outline-secondary-2.hover > img {
  opacity: 1;
}

.mol-btn-outline-secondary:focus, .mol-btn-outline-tag:focus, .mol-btn-outline-secondary.focus, .focus.mol-btn-outline-tag, .mol-btn-outline-secondary-2:focus, .mol-btn-outline-secondary-2.focus {
  border: 1px solid #CCCCCC !important;
  background-color: #F2F2F2 !important;
  box-shadow: 0 0 0 1px #F2F2F2 !important;
}

.mol-btn-outline-secondary-2 {
  border: 1px solid #E5E5E5 !important;
  background: linear-gradient(360deg, #F3F3F3, #F9F9F9 30%, #F9F9F9 40%, #fff) !important;
}

.mol-btn-outline-secondary-2:hover, .mol-btn-outline-secondary-2.hover {
  border: 1px solid #DFDFDF !important;
  background: linear-gradient(360deg, #eeeeee, #f4f4f4 30%, #f4f4f4 40%, #fdfdfd) !important;
}

.mol-btn-outline-secondary-2:hover > img, .mol-btn-outline-secondary-2.hover > img {
  opacity: 1;
}

.mol-btn-outline-secondary-2:focus, .mol-btn-outline-secondary-2.focus {
  border: 1px solid #DFDFDF !important;
  background: #F2F2F2 !important;
  box-shadow: 0 0 0 1px #F2F2F2 !important;
}

.mol-btn-outline-tag {
  color: #666666 !important;
  border-radius: .5rem !important;
}

.mol-btn-unstyled {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.mol-btn-unstyled img {
  height: 16px;
  opacity: .7;
}

.mol-btn-unstyled.mol-btn-small img {
  height: 12px;
}

.mol-btn-unstyled:hover img, .mol-btn-unstyled.hover img {
  opacity: 1;
}

.mol-btn-unstyled:focus, .mol-btn-unstyled.focus {
  box-shadow: none !important;
}

.mol-btn-close {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #FFF !important;
  border-radius: 50% !important;
  z-index: 1052;
}

.mol-btn-close img {
  height: 17px;
  opacity: .7;
}

.mol-btn-close:hover, .mol-btn-close.hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #DFDFDF !important;
}

.mol-btn-close:hover img, .mol-btn-close.hover img {
  opacity: 1;
}

.mol-btn-round-icon {
  width: 23px;
  height: 23px;
  padding: 4px !important;
  border-radius: 50% !important;
  border: 0 !important;
}

.mol-img-link {
  text-decoration: none !important;
}

.mol-img-link img {
  border: 0 !important;
}

.mol-btn-checkbox-imitation, .mol-btn-checkbox-imitation-deactivated {
  height: 17px;
  width: 17px;
  padding: 0 !important;
  color: #262626 !important;
  background: #fff !important;
  border: 1px solid #CCCCCC !important;
}

.mol-btn-checkbox-imitation > img, .mol-btn-checkbox-imitation-deactivated > img {
  height: 9px;
  width: auto;
  opacity: .8;
}

.mol-btn-checkbox-imitation:hover, .mol-btn-checkbox-imitation.hover, .mol-btn-checkbox-imitation-deactivated:hover, .mol-btn-checkbox-imitation-deactivated.hover {
  border: 1px solid #999999 !important;
  background-color: #FAFAFA !important;
}

.mol-btn-checkbox-imitation:hover > img, .mol-btn-checkbox-imitation.hover > img, .mol-btn-checkbox-imitation-deactivated:hover > img, .mol-btn-checkbox-imitation-deactivated.hover > img {
  opacity: 1;
}

.mol-btn-checkbox-imitation:focus, .mol-btn-checkbox-imitation.focus, .mol-btn-checkbox-imitation-deactivated:focus, .mol-btn-checkbox-imitation-deactivated.focus {
  border: 1px solid #999999 !important;
  background-color: #F2F2F2 !important;
  box-shadow: 0 0 0 1px #F2F2F2 !important;
}

.mol-btn-checkbox-imitation-deactivated {
  background-color: #F2F2F2 !important;
  cursor: default !important;
}

.mol-btn-checkbox-imitation-deactivated:hover, .mol-btn-checkbox-imitation-deactivated:focus {
  border: 1px solid #CCCCCC !important;
  background-color: #F2F2F2 !important;
}

.mol-merchant-card-mop-thumb-buttons-container {
  margin-left: -15px;
}

.mol-merchant-card-mop-thumb-button {
  margin-top: 6px;
  margin-left: 15px;
  border-radius: 0.2rem;
  height: 25px;
}

.mol-merchant-card-mop-thumb-button > img {
  height: 17px;
}

.mol-mop-invoive-thumb {
  line-height: 1;
  height: 17px;
  color: #FFF;
  background-color: #7DB560;
  border-radius: .2rem;
  font-size: 11px;
  padding: 5px;
}

.clickableImage {
  cursor: pointer;
}

.mol-brand-link {
  color: #666666 !important;
}

.mol-brand-link:hover {
  color: #404040 !important;
  text-decoration: none !important;
}

.mol-brand-link:hover .mol-brand-link-name {
  text-decoration: underline !important;
}

.mol-brand-name-desc {
  font-size: 0.857rem;
  color: #999999;
}

.mol-link-separater {
  color: #DFDFDF;
}

/**
 * container
 */
.mol-category-bestseller-listing-container {
  margin-left: -15px !important;
  margin-top: -15px !important;
}

.mol-category-bestseller-listing-item-container {
  padding-left: 15px;
  padding-top: 15px;
  width: 100%;
}

.mol-shop-listing-container {
  margin-left: -15px !important;
  margin-top: -15px !important;
}

.mol-shop-listing-item-container {
  padding-left: 15px;
  padding-top: 15px;
  width: 100%;
}

.mol-category-bestseller-listing-item,
.mol-shop-listing-item {
  width: 100%;
  padding: 0 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px #E5E5E5, 0px 2px 2px 0 #E5E5E5;
  border-radius: 0.2rem;
}

@media (min-width: 1200px) {
  .mol-category-bestseller-listing-item,
  .mol-shop-listing-item {
    box-shadow: 0 0 0 1px #E5E5E5, 0px 2px 2px 0 #E5E5E5;
  }
}

@media (min-width: 768px) {
  .mol-category-bestseller-listing-container {
    margin-left: -10px !important;
    margin-top: -12px !important;
  }
  .mol-category-bestseller-listing-item-container {
    padding-left: 10px;
    padding-top: 12px;
  }
  .mol-shop-listing-container {
    margin-left: -15px !important;
    margin-top: -15px !important;
  }
  .mol-shop-listing-item-container {
    padding-left: 15px;
    padding-top: 15px;
  }
}

@media (min-width: 992px) {
  .mol-category-bestseller-listing-container {
    margin-left: -10px !important;
    margin-top: -12px !important;
  }
  .mol-category-bestseller-listing-item-container {
    padding-left: 10px;
    padding-top: 12px;
  }
  .mol-shop-listing-container {
    margin-left: -15px !important;
    margin-top: -15px !important;
  }
  .mol-shop-listing-item-container {
    padding-left: 15px;
    padding-top: 15px;
  }
}

/**
 * category cards
 */
.mol-category-card-head,
.mol-shop-card-head {
  padding: 1rem 1rem;
  /*border-bottom: 1px solid transparent;

  &:hover {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-250;
    text-decoration: none;

    & + .mol-shop-card-head-divider,
    & + .mol-category-card-head-divider {
      opacity: .7;
      border-top:1px solid #f6f6f6;
    }
  }*/
}

@media (min-width: 992px) {
  .mol-category-card-head {
    padding: 1.3rem 1.7rem;
  }
  .mol-shop-card-head {
    padding: 1.3rem 2rem;
  }
}

.mol-category-card-head-name {
  font-size: 1.071rem;
  font-weight: 500;
}

@media (min-width: 768px) {
  .mol-category-card-head-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.mol-category-card-head-divider,
.mol-shop-card-head-divider {
  position: relative;
  height: 1.5rem;
  border-top: 1px solid #ECECEC;
  background-color: transparent;
  background: linear-gradient(180deg, #f6f6f6, #FFFFFF 1.5rem) no-repeat;
}

.mol-category-card-head-divider:after,
.mol-shop-card-head-divider:after {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 1.6rem;
  background-color: transparent;
  content: " ";
  background: linear-gradient(90deg, #FFFFFF, rgba(255, 255, 255, 0), #FFFFFF) no-repeat;
}

.mol-category-card-products {
  padding: 0 1rem;
}

@media (min-width: 576px) {
  .mol-category-card-products {
    padding: 0 40px;
  }
}

@media (min-width: 768px) {
  .mol-category-card-products {
    padding: 0 25px;
  }
}

@media (min-width: 992px) {
  .mol-category-card-products {
    padding: 0 55px;
  }
}

.mol-category-card-footer {
  padding: 1rem 1rem;
  padding-top: 1.5rem;
  font-size: 0.929rem;
}

.mol-category-card-footer > a {
  white-space: nowrap;
  font-size: 0.857rem;
  color: #262626;
}

.mol-category-card-footer > a > img {
  height: 9px;
  opacity: .8;
}

@media (min-width: 992px) {
  .mol-category-card-footer {
    padding: 1.3rem 1.8rem;
    padding-top: 1.5rem;
  }
}

/**
 * shop cards only
 */
.mol-shop-card-head-name {
  font-size: 1.071rem;
  color: #105074;
  font-weight: 500;
}

.mol-shop-card-head-name:hover {
  text-decoration: underline;
}

.mol-top-shop-card-head-name {
  font-size: 1.071rem;
  color: #262626;
}

.mol-shop-card-head-qty {
  color: #666666;
  font-size: 0.857rem;
  text-decoration: none !important;
}

.mol-shop-card-main {
  padding-bottom: 1rem;
}

.mol-shop-card-main .mol-shop-card-categories-block {
  padding: 0 1rem !important;
}

@media (min-width: 768px) {
  .mol-shop-card-main .mol-shop-card-categories-block {
    border-right: 1px solid #ECECEC;
  }
}

.mol-shop-card-main .mol-shop-card-categories-block .mol-shop-card-categories-list a:not(:first-of-type) {
  margin-top: .4rem;
}

.mol-shop-card-main .mol-shop-card-categories-block .mol-shop-card-categories-list a:hover {
  text-decoration: none;
}

.mol-shop-card-main .mol-shop-card-categories-block .mol-shop-card-categories-list a:hover .mol-shop-card-categories-list-item-name {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .mol-shop-card-main .mol-shop-card-categories-block .mol-shop-card-categories-list {
    max-width: calc(100% - 100px);
  }
}

@media (min-width: 992px) {
  .mol-shop-card-main .mol-shop-card-categories-block .mol-shop-card-categories-list {
    padding-left: 3.5rem !important;
  }
}

@media (min-width: 992px) {
  .mol-shop-card-main .mol-shop-card-categories-block {
    max-width: calc(100% - 430px);
  }
}

@media (min-width: 992px) {
  .mol-shop-card-main {
    padding-bottom: 1.4rem;
  }
  .mol-shop-card-main .mol-shop-card-categories-block {
    padding-left: 2rem !important;
    padding-right: 2.5rem !important;
    border-right: 1px solid #ECECEC;
  }
}

.mol-shop-card-products-footer {
  padding: 1rem 1rem;
  padding-top: 1.5rem;
  padding-bottom: 0px;
  font-size: 0.929rem;
}

.mol-shop-card-products-footer a {
  font-size: 0.857rem;
  font-weight: 400;
  color: #666666;
}

.mol-shop-card-products-footer a img {
  height: 9px;
  opacity: .7;
}

@media (min-width: 768px) {
  .mol-shop-card-products-footer a {
    white-space: nowrap;
  }
}

/**
 * divider
 */
.hr-light {
  border-top: 1px solid #ECECEC !important;
}

.hr-bold {
  border-top: 3px solid #E5E5E5 !important;
}

.mol-section-divider {
  height: 1px;
  border-top: 1px solid #ECECEC;
  margin: 0;
  padding: 0;
}

.mol-section-divider-dark {
  height: 1px;
  border-top: 1px solid #E5E5E5;
  margin: 0;
  padding: 0;
}

@media (min-width: 1200px) {
  .mol-page-box-xl-bordered .mol-section-divider, .mol-page-box-xl-bordered .mol-section-divider-dark {
    margin: 0 3rem;
  }
  /*.mol-section-divider-light {
    margin:0 3rem;
  }*/
}

.mol-section-divider-blur-wrapper {
  position: relative;
  height: 1px;
}

.mol-section-divider-blur {
  position: absolute;
  top: 0px;
  width: 100%;
  border-top: 1px solid #DFDFDF;
  background-color: transparent;
  background: linear-gradient(180deg, #f6f6f6, #FFFFFF 40px) no-repeat;
  height: 3rem;
}

.mol-section-divider-blur > .mol-section-divider-blur-overlay {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 3.05rem;
  background-color: transparent;
  background: linear-gradient(90deg, #FFFFFF, rgba(255, 255, 255, 0), #FFFFFF) no-repeat;
}

.mol-section-divider-blur-content {
  position: relative;
  padding-top: 2rem;
}

/**
 * product listingl filter selection box
 */
.mol-pl-filter-box {
  /*@include media-breakpoint-up(xl) {
    padding: .25rem 0;
  }*/
}

.mol-pl-filter-box-head {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.mol-pl-filter-box-head > .mol-pl-filter-box-head-btn {
  color: #262626;
}

@media (min-width: 1200px) {
  .mol-pl-filter-box-head > .mol-pl-filter-box-head-btn {
    font-size: 1.071rem;
    color: #777;
  }
}

.mol-pl-filter-box-head > .mol-pl-filter-box-head-btn > img {
  height: auto;
  width: 1rem;
  opacity: .6;
}

@media (min-width: 1200px) {
  .mol-pl-filter-box-head > .mol-pl-filter-box-head-btn > img {
    height: 7px;
    width: auto;
    opacity: 0;
  }
}

.mol-pl-filter-box-head > .mol-pl-filter-box-head-btn:hover {
  text-decoration: none;
}

.mol-pl-filter-box-head > .mol-pl-filter-box-head-btn:hover > img {
  opacity: .6;
}

@media (min-width: 1200px) {
  .mol-pl-filter-box-head > .mol-pl-filter-box-head-btn:hover > img {
    opacity: 0;
  }
}

.mol-pl-filter-box-head > .mol-pl-filter-box-head-btn:focus {
  text-decoration: none;
}

.mol-pl-filter-checkbox-elements-container {
  margin-top: -.5rem;
  margin-bottom: 1.5rem;
  transition: max-height .2s ease;
}

@media (min-width: 1200px) {
  .mol-pl-filter-checkbox-elements-container {
    margin-bottom: 1rem;
  }
}

.mol-pl-filter-checkbox-elements-container.mol-preview-box {
  max-height: 132px;
  overflow-y: hidden;
}

.mol-pl-filter-checkbox-elements-container.mol-scroll-box {
  max-height: 184px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mol-pl-filter-box-checkbox-element,
.mol-pl-filter-box-checkbox-element-active,
.mol-pl-filter-box-checkbox-element-deactivated {
  color: #666666;
  margin-top: .5rem !important;
}

@media (min-width: 1200px) {
  .mol-pl-filter-box-checkbox-element,
  .mol-pl-filter-box-checkbox-element-active,
  .mol-pl-filter-box-checkbox-element-deactivated {
    font-size: 0.929rem;
  }
}

.mol-pl-filter-box-checkbox-element:hover,
.mol-pl-filter-box-checkbox-element-active:hover,
.mol-pl-filter-box-checkbox-element-deactivated:hover {
  color: #404040;
}

.mol-pl-filter-box-checkbox-element-active {
  color: #262626;
  font-weight: 500;
}

.mol-pl-filter-box-checkbox-element-deactivated {
  color: #999999;
  opacity: .65 !important;
}

.mol-pl-filter-box-checkbox-element-deactivated:hover {
  color: #999999;
  text-decoration: none;
}

.mol-pl-filter-color-container {
  margin-top: -.75rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .mol-pl-filter-color-container {
    padding-left: 3px;
    margin-top: -.375rem;
    margin-bottom: 1rem;
  }
}

.mol-pl-filter-color-btn, .mol-pl-filter-color-btn-active, .mol-pl-filter-color-btn-deactivated {
  color: #262626 !important;
  background: #fff !important;
  border: 1px solid #CCCCCC !important;
  padding: 0px !important;
  border-radius: .3rem !important;
  margin-top: .75rem !important;
  margin-right: .5rem !important;
  height: 3rem !important;
  width: 3rem !important;
}

@media (min-width: 1200px) {
  .mol-pl-filter-color-btn, .mol-pl-filter-color-btn-active, .mol-pl-filter-color-btn-deactivated {
    border-radius: 50% !important;
    margin-top: .5rem !important;
    margin-right: .35rem !important;
    height: 1.6rem !important;
    width: 1.6rem !important;
  }
}

.mol-pl-filter-color-btn:hover, .mol-pl-filter-color-btn.hover, .mol-pl-filter-color-btn-active:hover, .mol-pl-filter-color-btn-active.hover, .mol-pl-filter-color-btn-deactivated:hover, .mol-pl-filter-color-btn-deactivated.hover {
  box-shadow: 0px 0px 0 1px #fff,  0px 0px 0 2px #999999 !important;
}

.mol-pl-filter-color-btn:focus, .mol-pl-filter-color-btn.focus, .mol-pl-filter-color-btn-active:focus, .mol-pl-filter-color-btn-active.focus, .mol-pl-filter-color-btn-deactivated:focus, .mol-pl-filter-color-btn-deactivated.focus {
  box-shadow: 0px 0px 0 1px #fff,  0px 0px 0 2px #999999 !important;
}

.mol-pl-filter-color-btn.mol-black, .mol-pl-filter-color-btn-active.mol-black, .mol-pl-filter-color-btn-deactivated.mol-black {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #262626 !important;
  border: 1px solid #000 !important;
}

.mol-pl-filter-color-btn.mol-gray, .mol-pl-filter-color-btn-active.mol-gray, .mol-pl-filter-color-btn-deactivated.mol-gray {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #999 !important;
  border: 1px solid #999 !important;
}

.mol-pl-filter-color-btn.mol-white, .mol-pl-filter-color-btn-active.mol-white, .mol-pl-filter-color-btn-deactivated.mol-white {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.025) 40%, rgba(0, 0, 0, 0) 80%), #fff !important;
  border: 1px solid #CCCCCC !important;
}

.mol-pl-filter-color-btn.mol-red, .mol-pl-filter-color-btn-active.mol-red, .mol-pl-filter-color-btn-deactivated.mol-red {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #d42929 !important;
  border: 1px solid #d42929 !important;
}

.mol-pl-filter-color-btn.mol-orange, .mol-pl-filter-color-btn-active.mol-orange, .mol-pl-filter-color-btn-deactivated.mol-orange {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #fb940b !important;
  border: 1px solid #fb940b !important;
}

.mol-pl-filter-color-btn.mol-yellow, .mol-pl-filter-color-btn-active.mol-yellow, .mol-pl-filter-color-btn-deactivated.mol-yellow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.03) 40%, rgba(0, 0, 0, 0) 80%), #ffff00 !important;
  border: 1px solid #DFDFDF !important;
}

.mol-pl-filter-color-btn.mol-green, .mol-pl-filter-color-btn-active.mol-green, .mol-pl-filter-color-btn-deactivated.mol-green {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #3bb63b !important;
  border: 1px solid #3bb63b !important;
}

.mol-pl-filter-color-btn.mol-blue, .mol-pl-filter-color-btn-active.mol-blue, .mol-pl-filter-color-btn-deactivated.mol-blue {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #1784b9 !important;
  border: 1px solid #1784b9 !important;
}

.mol-pl-filter-color-btn.mol-violet, .mol-pl-filter-color-btn-active.mol-violet, .mol-pl-filter-color-btn-deactivated.mol-violet {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #803bad !important;
  border: 1px solid #803bad !important;
}

.mol-pl-filter-color-btn.mol-pink, .mol-pl-filter-color-btn-active.mol-pink, .mol-pl-filter-color-btn-deactivated.mol-pink {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #ff98bf !important;
  border: 1px solid #ff98bf !important;
}

.mol-pl-filter-color-btn.mol-brown, .mol-pl-filter-color-btn-active.mol-brown, .mol-pl-filter-color-btn-deactivated.mol-brown {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #885418 !important;
  border: 1px solid #885418 !important;
}

.mol-pl-filter-color-btn.mol-beige, .mol-pl-filter-color-btn-active.mol-beige, .mol-pl-filter-color-btn-deactivated.mol-beige {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.03) 40%, rgba(0, 0, 0, 0) 80%), #f2e2d6 !important;
  border: 1px solid #DFDFDF !important;
}

.mol-pl-filter-color-btn.mol-silver, .mol-pl-filter-color-btn-active.mol-silver, .mol-pl-filter-color-btn-deactivated.mol-silver {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.04)), #c0c0c0 !important;
  border: 1px solid #DFDFDF !important;
}

.mol-pl-filter-color-btn.mol-gold, .mol-pl-filter-color-btn-active.mol-gold, .mol-pl-filter-color-btn-deactivated.mol-gold {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.04)), #e8cd62 !important;
  border: 1px solid #DFDFDF !important;
}

.mol-pl-filter-color-btn-active {
  box-shadow: 0px 0px 0 2px #fff,  0px 0px 0 3px #666 !important;
}

.mol-pl-filter-color-btn-deactivated {
  display: none !important;
}

.mol-pl-filter-sizes-container {
  margin-top: -.75rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .mol-pl-filter-sizes-container {
    margin-top: -.15rem;
    margin-bottom: 1rem;
  }
}

.mol-pl-filter-size-btn, .mol-pl-filter-size-btn-active, .mol-pl-filter-size-btn-deactivated {
  color: #666666 !important;
  border: 1px solid #CCCCCC !important;
  border-radius: .3rem !important;
  margin-top: .75rem !important;
  margin-right: .5rem !important;
  min-width: 3rem;
  padding: .3rem .45rem !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 80%), #FAFAFA !important;
}

@media (min-width: 1200px) {
  .mol-pl-filter-size-btn, .mol-pl-filter-size-btn-active, .mol-pl-filter-size-btn-deactivated {
    margin-top: .375rem !important;
    margin-right: .1rem !important;
    padding: .2rem .45rem !important;
    font-size: 0.929rem !important;
  }
}

.mol-pl-filter-size-btn:hover, .mol-pl-filter-size-btn.hover, .mol-pl-filter-size-btn-active:hover, .mol-pl-filter-size-btn-active.hover, .mol-pl-filter-size-btn-deactivated:hover, .mol-pl-filter-size-btn-deactivated.hover {
  border: 1px solid #999999 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 80%), #F2F2F2 !important;
}

.mol-pl-filter-size-btn:focus, .mol-pl-filter-size-btn.focus, .mol-pl-filter-size-btn-active:focus, .mol-pl-filter-size-btn-active.focus, .mol-pl-filter-size-btn-deactivated:focus, .mol-pl-filter-size-btn-deactivated.focus {
  border: 1px solid #999999 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 80%), #F2F2F2 !important;
  box-shadow: 0 0 0 1px #F2F2F2 !important;
}

.mol-pl-filter-size-btn-active {
  color: #262626 !important;
  font-weight: 500 !important;
  border: 1px solid #999999 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0) 80%), #ECECEC !important;
}

.mol-pl-filter-size-btn-deactivated {
  color: #999999 !important;
  border: 1px solid #F2F2F2 !important;
  background: #FAFAFA !important;
  cursor: default !important;
}

.mol-pl-filter-size-btn-deactivated:hover, .mol-pl-filter-size-btn-deactivated:focus {
  border: 1px solid #F2F2F2 !important;
  background: #FAFAFA !important;
}

/**
 * Filter tags
 */
.mol-pl-filter-tags-container {
  width: 100%;
  overflow-x: auto;
}

.mol-btn-filter-tag, .mol-btn-filter-tag-active, .mol-btn-filter-tag-delete-all {
  color: #262626 !important;
  font-size: 0.857rem !important;
  background: #fff !important;
  border: 1px solid #DFDFDF !important;
  border-radius: .5rem !important;
}

@media (min-width: 768px) {
  .mol-btn-filter-tag, .mol-btn-filter-tag-active, .mol-btn-filter-tag-delete-all {
    font-size: 0.929rem !important;
  }
}

.mol-btn-filter-tag > img, .mol-btn-filter-tag-active > img, .mol-btn-filter-tag-delete-all > img {
  height: .6rem;
  opacity: .6;
}

.mol-btn-filter-tag:hover, .mol-btn-filter-tag.hover, .mol-btn-filter-tag-active:hover, .mol-btn-filter-tag-active.hover, .mol-btn-filter-tag-delete-all:hover, .mol-btn-filter-tag-delete-all.hover {
  border: 1px solid #CCCCCC !important;
  background-color: #FAFAFA !important;
}

.mol-btn-filter-tag:hover > img, .mol-btn-filter-tag.hover > img, .mol-btn-filter-tag-active:hover > img, .mol-btn-filter-tag-active.hover > img, .mol-btn-filter-tag-delete-all:hover > img, .mol-btn-filter-tag-delete-all.hover > img {
  opacity: 1;
}

.mol-btn-filter-tag:focus, .mol-btn-filter-tag.focus, .mol-btn-filter-tag-active:focus, .mol-btn-filter-tag-active.focus, .mol-btn-filter-tag-delete-all:focus, .mol-btn-filter-tag-delete-all.focus {
  border: 1px solid #CCCCCC !important;
  background-color: #F2F2F2 !important;
  box-shadow: 0 0 0 1px #F2F2F2 !important;
}

.mol-btn-filter-tag-active {
  background: #e6f1f7 !important;
}

.mol-btn-filter-tag-active:hover, .mol-btn-filter-tag-active.hover, .mol-btn-filter-tag-active:focus, .mol-btn-filter-tag-active.focus {
  background-color: #deedf5 !important;
}

.mol-btn-filter-tag-delete-all {
  background: #FAFAFA !important;
}

.mol-btn-filter-tag-delete-all:hover, .mol-btn-filter-tag-delete-all.hover, .mol-btn-filter-tag-delete-all:focus, .mol-btn-filter-tag-delete-all.focus {
  background-color: whitesmoke !important;
}

/**
 * forms
 */
.mol-search-input-group {
  border: 1px solid #DFDFDF;
  border-radius: 0.2rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.03);
}

.mol-search-input-group:focus-within {
  border: 1px solid #CCCCCC;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07);
}

.mol-search-input {
  border: 1px solid #fff !important;
  padding: 0.5rem 0.75rem !important;
  height: auto !important;
}

.mol-search-input.mol-cce-search-input {
  /*&::placeholder {
      color:#105074; //#B3CCDA;
      opacity: .5;
    }*/
}

.mol-search-input:focus {
  border: 1px solid #fff !important;
  box-shadow: none !important;
}

.mol-btn-search {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  border: 1px solid #fff !important;
}

.mol-btn-search > img {
  opacity: .7;
}

.mol-btn-search:hover, .mol-btn-search:focus {
  background-color: #F2F2F2 !important;
  border: 1px solid #F2F2F2 !important;
  box-shadow: none !important;
}

.mol-btn-search:hover > img, .mol-btn-search:focus > img {
  opacity: 1;
}

.mol-btn-search.mol-btn-cce-search {
  background: linear-gradient(90deg, #FFF, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0.1) 100%), url("../../images/mol/templ/cce_search_btn_bg.png") no-repeat right;
}

.mol-btn-search.mol-btn-cce-search > img {
  opacity: .7;
}

.mol-btn-search.mol-btn-cce-search:hover, .mol-btn-search.mol-btn-cce-search:focus {
  border: 1px solid #fff !important;
  box-shadow: none !important;
  /*background:
        linear-gradient(90deg, #FFF, rgba(255, 255, 255, .9) 30%, rgba(255, 255, 255, .8) 60%, rgba(255, 255, 255, .1) 100%),
        url("../../images/mol/templ/cce_search_btn_bg.png") no-repeat right;
      */
}

.mol-btn-search.mol-btn-cce-search:hover > img, .mol-btn-search.mol-btn-cce-search:focus > img {
  opacity: 1;
}

.mol-msg-textarea {
  height: 7rem !important;
}

@media (min-width: 768px) {
  .mol-msg-textarea {
    height: 10rem !important;
  }
}

@media (min-width: 1200px) {
  .mol-msg-textarea {
    height: 14rem !important;
  }
}

/**
 * Labels
 */
.col-form-label.mol-form-label, .col-form-label.mol-form-label-for-optionals {
  font-size: 0.929rem;
  font-weight: 500;
}

.col-form-label.mol-form-label .mol-form-optional-mark, .col-form-label.mol-form-label-for-optionals .mol-form-optional-mark {
  font-size: 0.786rem;
  color: #999999;
  font-weight: normal;
}

@media (min-width: 768px) {
  .col-form-label.mol-form-label {
    font-size: 1rem;
  }
  .col-form-label.mol-form-label-for-optionals {
    font-size: 1rem;
    padding-bottom: 0 !important;
  }
  .col-form-label.mol-form-label-for-optionals.mol-form-label-dob {
    line-height: 1 !important;
  }
  .col-form-label.mol-form-label-for-optionals.mol-form-label-dob .mol-form-optional-mark {
    line-height: 1;
  }
}

/**
 * Misc
 */
@media (min-width: 768px) {
  .mol-address-form-container {
    max-width: 500px;
  }
}

/**
 * Modal customization
 */
body.mol-modal-open-body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal.mol-full-width-modal {
  padding-right: 0 !important;
}

.modal.mol-ccep-modal {
  padding-right: 0 !important;
}

.modal-dialog-centered::before {
  display: none !important;
  content: none !important;
}

.modal-dialog {
  margin: 15px !important;
  min-height: calc(100% - (15px * 2)) !important;
}

.mol-full-width-modal > .modal-dialog {
  margin: 5px !important;
  min-height: calc(100% - (5px * 2)) !important;
}

.mol-ccep-modal > .modal-dialog {
  margin: 5px !important;
  min-height: calc(100% - (5px * 2)) !important;
}

@media (min-width: 768px) {
  .mol-full-width-modal > .modal-dialog {
    margin: 5px !important;
    min-height: calc(100% - (5px * 2)) !important;
  }
  .mol-ccep-modal > .modal-dialog {
    margin: 5px !important;
    min-height: calc(100% - (5px * 2)) !important;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 940px !important;
    margin: 15px auto !important;
  }
  .modal-dialog.mol-modal-sm {
    max-width: 500px !important;
  }
  .mol-full-width-modal > .modal-dialog {
    max-width: 940px !important;
    margin: 15px auto !important;
    min-height: calc(100% - (15px * 2)) !important;
  }
  .mol-ccep-modal > .modal-dialog {
    max-width: 940px !important;
    margin: 15px auto !important;
    min-height: calc(100% - (15px * 2)) !important;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 940px !important;
  }
  .modal-dialog.mol-modal-sm {
    max-width: 500px !important;
  }
  .mol-full-width-modal > .modal-dialog {
    max-width: 100% !important;
    margin: 15px 70px !important;
  }
  .mol-ccep-modal > .modal-dialog {
    width: 1300px !important;
    max-width: calc(100vw - (2px + 15px + 15px)) !important;
  }
}

.mol-viewport-modal-frame {
  border-radius: .2rem;
  width: 100%;
  border: 0;
  margin: 0;
  box-sizing: border-box;
  height: calc(100vh - (2px + 15px + 15px));
  max-height: 800px;
}

.mol-modal-sm .mol-viewport-modal-frame {
  max-height: 600px;
}

.mol-ccep-modal .mol-viewport-modal-frame {
  height: calc(100vh - (2px + 5px + 5px));
  max-height: none;
}

@media (min-width: 992px) {
  .mol-ccep-modal .mol-viewport-modal-frame {
    height: calc(100vh - (2px + 15px + 15px));
  }
}

@media (min-width: 1200px) {
  .mol-ccep-modal .mol-viewport-modal-frame {
    max-height: 900px;
  }
}

.mol-viewport-modal-frame-content-container {
  height: calc(100vh - (2px + 15px + 15px));
  max-height: 800px;
}

.mol-viewport-modal-frame-content-container.mol-sm-modal-content {
  max-height: 600px;
}

.mol-ccep-viewport-modal-frame-content-container {
  height: calc(100vh - (2px + 5px + 5px));
  max-height: none;
}

@media (min-width: 992px) {
  .mol-ccep-viewport-modal-frame-content-container {
    height: calc(100vh - (2px + 15px + 15px));
    max-height: 900px;
  }
}

@media (min-width: 1200px) {
  .mol-ccep-viewport-modal-frame-content-container {
    max-height: 900px;
  }
}

.mol-viewport-modal-frame-content-container_scroll_section {
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/**
 * category nav
 */
.mol-category-nav-item {
  display: block;
  color: #262626;
  padding-top: .3rem;
  padding-bottom: .3rem;
  line-height: 1.4;
}

.mol-category-nav-item:first-of-type {
  padding-top: 0;
}

@media (min-width: 1200px) {
  .mol-category-nav-item {
    padding-top: .22rem;
    padding-bottom: .22rem;
  }
  .mol-category-nav-item:first-of-type {
    padding-top: 0;
  }
  .mol-start-categories-box .mol-category-nav-item {
    padding-top: .27rem;
    padding-bottom: .27rem;
  }
  .mol-start-categories-box .mol-category-nav-item:first-of-type {
    padding-top: 0;
  }
}

.mol-category-nav-item.active {
  text-decoration: none;
  font-weight: 500;
}

.mol-category-nav-item.current {
  text-decoration: underline;
  font-weight: 700;
}

.mol-category-nav-item.mol-level-1 {
  color: #197EB7;
}

.mol-category-nav-item.mol-level-1:hover {
  color: #105074;
}

.mol-category-nav-item.mol-level-1.mol-market-category {
  color: #166fa1;
}

.mol-category-nav-item.mol-level-1.mol-result-shop-category {
  color: #197EB7;
}

.mol-category-nav-item.mol-level-1.mol-result-shop-category:hover {
  color: #105074;
}

.mol-category-nav-item.mol-level-2 {
  padding-left: 1.5rem;
  color: #262626;
}

.mol-category-nav-item.mol-level-2:hover {
  color: black;
}

.mol-category-nav-item.mol-level-2.mol-market-category {
  color: #105074;
}

.mol-category-nav-item.mol-level-3 {
  padding-left: 3rem;
  color: #197EB7;
}

.mol-category-nav-item.mol-level-3:hover {
  color: #105074;
}

.mol-category-nav-item:hover {
  text-decoration: underline;
  /*& .mol-category-nav-item-arrow {
      opacity: .7;
    }*/
}

@media (min-width: 1200px) {
  .mol-category-nav-back-link {
    color: #666 !important;
    font-size: 0.857rem !important;
    padding-top: .15rem;
    padding-bottom: .15rem;
    /*& ~ .mol-category-nav-item:not(.mol-category-nav-back-link) {
      margin-left:11px;
    }*/
  }
  .mol-category-nav-back-link:first-of-type {
    padding-top: 0;
  }
  .mol-category-nav-back-link:last-of-type {
    padding-bottom: 0;
  }
  .mol-category-nav-back-link .mol-category-nav-item-back-arrow {
    opacity: .4;
    margin-right: 6px;
    height: 9px;
    width: 5px;
  }
  .mol-category-nav-back-link:hover {
    text-decoration: none;
    color: #515151 !important;
  }
  .mol-category-nav-back-link:hover .mol-text-hover-underline {
    text-decoration: underline;
  }
}

/**
 * arrow
 */
.mol-category-nav-item-arrow {
  margin-right: .5rem;
  opacity: .5;
  height: 9px;
  width: 5px;
  /*.active &,
  .current & {
    opacity: .7;
  }*/
}

@media (min-width: 1200px) {
  .mol-category-nav-item-arrow {
    margin-right: 6px;
    opacity: .4;
    height: 9px;
    width: 5px;
  }
}

/*
 * Offcanvas till XL
 */
.mol-offcanvas-dialog.mol-cat-menu-dialog .mol-category-nav-item {
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .mol-offcanvas-dialog.mol-cat-menu-dialog .mol-category-nav-item {
    font-size: 0.929rem;
  }
  .mol-offcanvas-dialog.mol-cat-menu-dialog .mol-category-nav-item.mol-shop-start-page-category {
    font-size: 0.929rem;
    color: #197EB7;
    /*
      &:first-of-type {
        padding-top: 0;
      }*/
  }
  .mol-offcanvas-dialog.mol-cat-menu-dialog .mol-category-nav-item.mol-market-category {
    font-size: 0.929rem;
  }
  .mol-offcanvas-dialog.mol-cat-menu-dialog .mol-category-nav-item.mol-brand-link {
    font-size: 0.929rem;
  }
}

/*
 * Content section navigator
 */
.mol-content-section-navigator {
  border-bottom: 1px solid #F2F2F2;
}

/**
 * Offcanvas
 */
.mol-offcanvas {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.mol-offcanvas.mol-offcanvas-open {
  visibility: visible;
  opacity: 1;
}

.mol-offcanvas-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 90%;
  max-width: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  box-shadow: 0 .1rem .4rem #000;
  opacity: 1;
  z-index: 2010;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  -webkit-transform: translateX(-101%);
  transform: translateX(-101%);
}

.mol-offcanvas-dialog.mol-offcanvas-from-right {
  right: 0;
  -webkit-transform: translateX(101%);
  transform: translateX(101%);
}

.mol-offcanvas-dialog.mol-offcanvas-open {
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.mol-offcanvas-backdrop-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mol-offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  transition: opacity .3s;
  opacity: 0;
  z-index: 2000;
}

.mol-offcanvas-backdrop.mol-offcanvas-backdrop-open {
  opacity: 0.7;
}

.mol-ccep-color-card-offcanvas .mol-offcanvas-backdrop.mol-offcanvas-backdrop-open,
.mol-ccep-selected-color-offcanvas .mol-offcanvas-backdrop.mol-offcanvas-backdrop-open {
  opacity: .5;
}

.mol-offcanvas-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.mol-offcanvas-head, .mol-offcanvas-sub-head {
  background-color: #F2F2F2;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #E5E5E5;
}

.mol-offcanvas-sub-head {
  border-bottom: 1px solid #E5E5E5;
  background-color: #FAFAFA;
}

.mol-offcanvas-body {
  background-color: #fff;
  padding-bottom: 1rem;
}

.mol-offcanvas-item {
  display: block;
  color: #262626;
  background-color: #fff;
  padding: 1rem 1.5rem;
}

.mol-offcanvas-item .mol-icon-container {
  width: 1.9rem;
  min-width: 1.9rem;
  margin-right: .5rem;
  line-height: 0;
}

.mol-offcanvas-item .mol-icon-container > img {
  height: .8rem;
  opacity: .7;
}

.mol-offcanvas-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid #E5E5E5;
}

.mol-offcanvas-divider-dark {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid #DFDFDF;
}

/**
 * offcanvas adption for category nav Menu
 * which isn't an offcanvas at xl
 */
.mol-offcanvas-dialog.mol-cat-menu-dialog {
  left: 0;
}

@media (min-width: 1200px) {
  .mol-offcanvas-dialog.mol-cat-menu-dialog {
    position: static !important;
    top: auto !important;
    bottom: auto !important;
    width: auto !important;
    max-width: none !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: transparent !important;
    box-shadow: none !important;
    opacity: 1;
    z-index: auto !important;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: none !important;
    transform: none !important;
  }
  .mol-offcanvas-dialog.mol-cat-menu-dialog > .mol-offcanvas-body {
    padding-bottom: 0;
    background-color: transparent;
  }
  .mol-offcanvas-dialog.mol-cat-menu-dialog > .mol-offcanvas-body .mol-offcanvas-item {
    max-width: 220px;
    background-color: transparent;
  }
  .mol-offcanvas.mol-cat-menu {
    position: static !important;
    visibility: visible !important;
    width: 240px;
    opacity: 1 !important;
  }
  .mol-offcanvas.mol-cat-menu.mol-ccep-selected-color-offcanvas {
    width: 320px;
  }
  .mol-offcanvas.mol-cat-menu.mol-cat-menu-slim {
    width: 210px;
    padding-right: 1rem;
  }
  .mol-offcanvas.mol-cat-menu > .mol-offcanvas-backdrop:not(.mol-offcanvas-backdrop-open) {
    visibility: hidden;
  }
}

@media (min-width: 1200px) {
  .mol-cat-menu-dialog .mol-offcanvas-item {
    padding: 1rem 0;
  }
  .mol-cat-menu-dialog .mol-offcanvas-divider {
    height: 0;
    margin: 0;
    overflow: hidden;
    border-top: 1px solid #ECECEC;
  }
}

@media (min-width: 1200px) {
  .mol-offcanvas-sub-head {
    margin-top: 4px;
    border-top: 1px solid #ECECEC;
    border-bottom: 0;
    padding: 1rem 0;
    padding-top: 1.6rem !important;
    background-color: transparent;
  }
}

/**
 * Pagination
 */
.page-link > img {
  height: 13px;
  opacity: .8;
}

.mol-next:not(.disabled) .page-link,
.mol-prev:not(.disabled) .page-link {
  background: linear-gradient(180deg, #fff, #fff 40%, #F9F9F9);
}

@media (min-width: 768px) {
  .mol-next:not(.disabled) .page-link,
  .mol-prev:not(.disabled) .page-link {
    background: #fff;
  }
}

.mol-next:not(.disabled) .page-link:hover,
.mol-prev:not(.disabled) .page-link:hover {
  background: #F2F2F2;
}

.page-item:not(.active):not(.mol-prev):not(.mol-next) {
  display: none;
}

.page-item.disabled .page-link > img {
  opacity: .5;
}

@media (min-width: 768px) {
  .active .page-link {
    z-index: 3 !important;
    color: #fff !important;
    background-color: #156898 !important;
    border-color: #156898 !important;
  }
  .page-item:not(.active):not(.mol-prev):not(.mol-next) {
    display: list-item;
  }
}

/**
 * Popovers
 */
.popover {
  border: 1px solid #DFDFDF !important;
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.15) !important;
}

/**
 * Dropdowns
 */
.dropdown-menu {
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.15) !important;
}

.dropdown-header {
  position: relative;
  top: -0.5rem;
  padding: 0.9rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500;
  color: #262626 !important;
  background-color: #F2F2F2;
}

@media (min-width: 576px) {
  .mol-dropdown-menu-sm-right {
    right: 0 !important;
    left: auto !important;
  }
}

/**
 * container
 */
.mol-product-listing-container {
  border-bottom: 1px solid #E5E5E5;
}

@media (min-width: 576px) {
  .mol-product-listing-container {
    margin-left: -10px !important;
    border-bottom: none;
  }
}

.mol-product-listing-item {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .mol-product-listing-item {
    padding-left: 10px;
    padding-bottom: 11px;
  }
}

.mol-category-bestseller-listing-item .mol-product-listing-container,
.mol-shop-listing-item-container .mol-product-listing-container {
  margin-left: -1rem !important;
  border-bottom: none;
}

@media (min-width: 992px) {
  .mol-shop-listing-item-container .mol-product-listing-container {
    width: 330px;
    max-width: 330px;
  }
}

.mol-category-bestseller-listing-item .mol-product-listing-item,
.mol-shop-listing-item .mol-product-listing-item {
  width: auto;
  padding-left: 1rem;
  padding-bottom: 0;
}

/**
 * product cards
 */
.mol-product-card {
  position: relative;
  padding: 1rem 0;
  margin: 0 0;
  width: 100%;
  color: #262626;
  border-top: 1px solid #E5E5E5;
  background-color: #fff;
  background: #fff;
}

.mol-product-card:hover, .mol-product-card:focus {
  color: #105074;
  text-decoration: none;
  outline: 0;
}

@media (min-width: 576px) {
  .mol-product-card {
    padding: 1rem 1rem;
    border-top: none;
    box-shadow: 0 0 0 1px #E5E5E5, 0px 2px 2px 0 #E5E5E5;
    border-radius: 0.2rem;
    background: linear-gradient(0deg, #fafafa, #fff 30%);
  }
  .mol-product-card:hover, .mol-product-card:focus {
    color: #105074;
    text-decoration: none;
    transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
    box-shadow: 0 0 0 1px #CCCCCC, 0 2px 2px 0 #CCCCCC;
  }
}

@media (min-width: 1200px) {
  .mol-product-card {
    padding: 1.25rem;
    box-shadow: 0 0 0 1px #E8E8E8, 0px 2px 2px 0 #E5E5E5;
  }
  .mol-product-card:hover, .mol-product-card:focus {
    transition: border .3s ease-in-out, box-shadow .2s ease-in-out;
    box-shadow: 0 0 0 1px #CCCCCC, 0 2px 2px 0 #CCCCCC;
  }
}

.mol-product-card-img {
  border: 1px solid transparent;
  width: 100px;
  max-height: 150px;
}

.mol-product-card-img img {
  flex: 0 0 auto;
  object-fit: contain;
  width: auto;
  max-height: 100%;
}

@media (min-width: 576px) {
  .mol-product-card-img {
    height: 170px;
    max-height: none;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .mol-product-card-img {
    height: 190px;
  }
}

.mol-product-card-text-block {
  padding-left: 1rem;
  min-width: 1px;
  -ms-flex-negative: 1;
}

@media (min-width: 576px) {
  .mol-product-card-text-block {
    padding-left: 0;
  }
}

.mol-product-card-name-outer {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

@media (min-width: 768px) {
  .mol-product-card-name-outer {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

.mol-product-card-name-inner {
  word-wrap: break-word;
  overflow: hidden;
}

.mol-product-card-name-inner:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .mol-product-card-name-inner {
    margin-top: .5rem;
  }
}

@media (min-width: 1200px) {
  .mol-product-card-name-inner {
    margin-top: 1rem;
  }
}

.mol-product-card-name-variant {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: 0.857rem;
  color: #999999;
}

.mol-product-card-name {
  -ms-flex-positive: 0;
  flex-grow: 0;
  word-wrap: break-word;
  overflow: hidden;
}

.mol-product-card-name:hover {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .mol-product-card-name {
    margin-top: .5rem;
  }
}

@media (min-width: 768px) {
  .mol-product-card-name {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media (min-width: 1200px) {
  .mol-product-card-name {
    margin-top: 1rem;
  }
}

.mol-product-card-energy-efficiency {
  margin-top: .25rem;
  font-size: 0.857rem;
  color: #999999;
}

.mol-product-card-price {
  margin-top: .5rem;
  font-size: 1.143rem;
  line-height: 1.4;
  color: #262626;
  font-weight: 500;
}

@media (min-width: 576px) {
  .mol-product-card-price {
    margin-top: .75rem;
    line-height: 1.3;
  }
}

.mol-product-card-offer-price-rel {
  margin-top: .5rem;
  font-size: 0.929rem;
  color: #262626;
}

.mol-product-card-offer-price-rel > .mol-product-card-offer-price-rel-text {
  display: inline;
  text-decoration: line-through;
}

@media (min-width: 576px) {
  .mol-product-card-offer-price-rel {
    margin-top: .75rem;
  }
}

.mol-product-card-offer-price {
  font-size: 1.143rem;
  line-height: 1.3;
  color: #B62500;
  font-weight: 500;
}

@media (min-width: 576px) {
  .mol-product-card-offer-price {
    line-height: 1.2;
  }
}

.mol-product-card-offer-price-mark {
  position: absolute;
  top: 1rem;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.2rem;
  box-shadow: 1px 1px 3px #DFDFDF;
  height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  color: #B62500;
}

@media (min-width: 576px) {
  .mol-product-card-offer-price-mark {
    top: 1rem;
    left: 1rem;
    height: 26px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.mol-product-card-unit-price {
  font-size: 0.857rem;
  color: #999999;
}

.mol-product-card-shipping-cost {
  font-size: 0.857rem;
  color: #999999;
}

/****************************************
 ****************************************
 * product card adaption for carousels
 ****************************************
 */
.mol-product-carousel-item > .mol-product-card {
  padding: 1rem 1rem;
  border-radius: 0.2rem;
  box-shadow: none;
  border: 1px solid #E5E5E5;
  background: linear-gradient(0deg, #FBFBFB, #fff 30%);
}

.mol-start-recommended-products .mol-product-carousel-item > .mol-product-card {
  background: linear-gradient(0deg, #FAFAFA, #fff 30%);
}

.mol-product-carousel-item > .mol-product-card:hover, .mol-product-carousel-item > .mol-product-card:focus {
  color: #105074;
  text-decoration: none;
  transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
  border: 1px solid #CCCCCC;
  box-shadow: 0 1px 1px 0 #DFDFDF;
  outline: 0;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-img {
  height: 120px;
  max-height: none;
  width: auto;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-name {
  margin-top: .5rem;
  font-size: 0.929rem;
  line-height: 1.4;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-name-outer {
  margin-top: .5rem;
  font-size: 0.929rem;
  line-height: 1.4;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-price {
  font-size: 1rem;
  margin-top: .5rem;
  font-weight: 500;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-offer-price-rel {
  margin-top: .5rem;
}

.mol-product-carousel-item > .mol-product-card > .mol-product-card-offer-price {
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 576px) {
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-img {
    height: 120px;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 768px) {
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-name {
    font-size: 1rem;
  }
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-name-outer {
    font-size: 1rem;
  }
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-price {
    margin-top: .75rem;
  }
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-price {
    margin-top: .75rem;
  }
}

@media (min-width: 1200px) {
  .mol-product-carousel-item > .mol-product-card {
    border: 1px solid #ECECEC;
    padding: 1rem 1.25rem;
  }
  .mol-product-carousel-item > .mol-product-card > .mol-product-card-img {
    height: 130px;
  }
  .mol-start-recommended-products .mol-product-carousel-item > .mol-product-card {
    border: 1px solid #E8E8E8;
  }
  .mol-start-recommended-products .mol-product-carousel-item > .mol-product-card:hover, .mol-start-recommended-products .mol-product-carousel-item > .mol-product-card:focus {
    color: #105074;
    text-decoration: none;
    transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
    border: 1px solid #CCCCCC;
    box-shadow: 0 1px 1px 0 #DFDFDF;
    outline: 0;
  }
}

/**********************************************
 **********************************************
 * product card adaption for catggory bestseller listings
 **********************************************
 */
.mol-category-bestseller-listing-item .mol-product-card,
.mol-shop-listing-item .mol-product-card {
  color: #262626;
  padding: 0 0;
  border-top: none;
  box-shadow: none;
  background: #fff;
}

.mol-category-bestseller-listing-item .mol-product-card:hover, .mol-category-bestseller-listing-item .mol-product-card:focus,
.mol-shop-listing-item .mol-product-card:hover,
.mol-shop-listing-item .mol-product-card:focus {
  color: #105074;
  text-decoration: none;
  box-shadow: none;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-img,
.mol-shop-listing-item .mol-product-card > .mol-product-card-img {
  align-self: center !important;
  height: 130px;
  max-height: none;
  width: auto;
  max-width: 140px;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-img > img,
.mol-shop-listing-item .mol-product-card > .mol-product-card-img > img {
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block {
  padding-left: 0;
  text-align: center;
  color: inherit;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-name,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-name {
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin-top: .5rem;
  font-size: 0.857rem;
  line-height: 1.4;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-price,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-price {
  color: #262626;
  font-size: 1rem;
  margin-top: .5rem;
  font-weight: 500;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-offer-price-rel,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-offer-price-rel {
  color: #666666;
  margin-top: .5rem;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-offer-price,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-offer-price {
  color: #262626;
  font-size: 1rem;
  font-weight: 500;
}

.mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-unit-price,
.mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-unit-price {
  margin-top: 1px;
}

@media (min-width: 576px) {
  .mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-name,
  .mol-shop-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-name {
    font-size: 0.929rem;
  }
}

@media (min-width: 768px) {
  .mol-category-bestseller-listing-item .mol-product-card,
  .mol-shop-listing-item .mol-product-card {
    padding: 0 .5rem;
  }
}

@media (min-width: 992px) {
  .mol-category-bestseller-listing-item .mol-product-card,
  .mol-shop-listing-item .mol-product-card {
    padding: 0 1rem;
  }
  .mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-img,
  .mol-shop-listing-item .mol-product-card > .mol-product-card-img {
    align-self: center !important;
    height: 130px;
    width: auto;
    max-width: 140px;
  }
  .mol-category-bestseller-listing-item .mol-product-card > .mol-product-card-text-block > .mol-product-card-name {
    font-size: 1rem;
    line-height: 1.5;
  }
}

/*@include media-breakpoint-up(lg) {
  .mol-category-bestseller-listing-item .mol-product-card {
    & > .mol-product-card-text-block {
      & > .mol-product-card-name {
        font-size: 1rem;
      }
    }
  }
}*/
/**********************************************
 **********************************************
 * product card adaption for sidebar listings
 **********************************************
 */
/*
.mol-sidebar-product-listing-item > .mol-product-card {
  padding: 1rem 0rem;
  border-radius: $border-radius;
  box-shadow: none;
  border: none;

  & > .mol-product-card-img {
    border:1px solid transparent;
    width: 90px;
    height: auto;

    & > img {
      flex: 0 0 auto; // makes IE 11 resizing the img-width correctly
      max-height: 100%;
    }
  }

  & > .mol-product-card-text-block {
    padding-left: 1rem;

    & > .mol-product-card-name {
      margin-top: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      font-size: $mol-smaller-font-size;
    }

    & > .mol-product-card-price {
      margin-top: .25rem;
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}
*/
/**
 * section boxes
 *
 * follows this structure:
 * - page-box (box around the complete main content)
 *  - pb-content-section
 *    - pb-content-section-inner
 *
 * section divider should be outside the content-section element
 */
.mol-page-box {
  padding-bottom: 1rem;
}

.mol-p-b-content-section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .mol-p-b-content-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.mol-col-left-menu-section {
  height: 0px !important;
  min-height: 0px !important;
}

.mol-p-b-content-section.mol-left-menu-section {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .mol-col-left-menu-section {
    position: static !important;
    min-height: 1px !important;
  }
  .mol-p-b-content-section.mol-left-menu-section {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: .5rem;
    padding-top: 1rem;
    padding-left: 0px;
    padding-bottom: 0px;
    /*position:relative;

    &:after {
      position:absolute;
      bottom: 0;
      right: -1px;
      height: 50px;
      width: 1px;
      content: " ";
      background: linear-gradient(to top, #fff, transparent 50px),
    }*/
  }
}

.mol-page-box-xl-bordered {
  padding-bottom: .5rem;
}

@media (min-width: 1200px) {
  .mol-page-box-xl-bordered {
    margin-top: 1.2rem;
    background-color: #FFF;
    border: 1px solid #E8E8E8;
    border-bottom: 0;
    border-radius: 0.2rem 0.2rem 0 0;
    box-shadow: 0 5px 3px 2px rgba(0, 0, 0, 0.05);
  }
}

.mol-p-b-xl-b-content-section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .mol-p-b-xl-b-content-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

/*
@include media-breakpoint-up(xl) {
  .mol-content-card {
    //margin-top: 2rem;
    //margin-bottom: 2rem;
    //border: 1px solid $border-color;
    //border-radius: $border-radius;
    //padding: 1.5rem;
    //background-color: $white;
    //box-shadow: 0 1px 3px rgba(0,0,0,.15);
    //box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  }
}
*/
@media (min-width: 1200px) {
  .mol-p-b-xl-b-content-section-inner {
    padding: 0 3rem !important;
  }
}

/**
 * container
 */
.mol-topic-container {
  width: 100%;
  border: 1px solid #ECECEC;
  border-radius: 0.2rem;
}

@media (min-width: 768px) {
  .mol-topic-container {
    border: 1px solid #E5E5E5;
  }
}

@media (min-width: 1200px) {
  .mol-topic-container {
    /*&.product-page-topic {
      max-width: 930px;
    }*/
  }
}

/**
 * ColorExpress
 */
.mol-topic-item-colorexpress {
  position: relative;
  width: 100%;
  padding: 1rem 1.5rem;
  text-decoration: none;
  color: #262626;
  background: linear-gradient(45deg, #F3F3F3, #FCFCFC 30%);
}

.mol-topic-item-colorexpress:hover {
  color: black;
  text-decoration: none;
}

@media (min-width: 576px) {
  .mol-topic-item-colorexpress {
    padding: 1rem 1.5rem;
    background: linear-gradient(45deg, #F3F3F3, #FCFCFC 30%);
  }
}

@media (min-width: 768px) {
  .mol-topic-item-colorexpress {
    padding: 0 2rem;
    padding: 0 1rem;
    background: linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%), linear-gradient(75deg, #FFFFFF, #FFFFFF 70%, rgba(255, 255, 255, 0.1) 100%), url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 180px no-repeat;
  }
  .mol-topic-item-colorexpress.mol-topic-item-colorexpress-2 {
    padding: 0 1rem;
    background: linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%), linear-gradient(75deg, #FFFFFF, #FFFFFF 70%, rgba(255, 255, 255, 0.1) 100%), url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 180px no-repeat;
  }
}

@media (min-width: 992px) {
  .mol-topic-item-colorexpress {
    height: auto;
    padding: 0 2rem;
    background: linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%), linear-gradient(75deg, #FFFFFF, #FFFFFF 79%, rgba(255, 255, 255, 0) 100%), url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 200px no-repeat;
  }
  .mol-topic-item-colorexpress.mol-topic-item-colorexpress-2 {
    height: auto;
    background: linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%), linear-gradient(75deg, #FFFFFF, #FFFFFF 74%, rgba(255, 255, 255, 0) 100%), url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 200px no-repeat;
  }
}

@media (min-width: 1200px) {
  .mol-topic-item-colorexpress {
    padding: 0 2rem;
  }
}

.mol-merchant-mop-button {
  font-size: 0.857rem;
  padding-top: 8px;
  width: 97px;
  border-radius: 4px;
  border: 1px solid #ECECEC;
}

.mol-merchant-mop-button:hover {
  background-color: #FAFAFA;
}

.mol-merchant-mop-logo {
  height: 20px;
}

.mol-header-container {
  background-color: #F9F9F9;
  border-bottom: 1px solid #E5E5E5;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.03);
}

.mol-header-container.mol-header-checkout {
  border-bottom: 1px solid #E8E8E8;
}

.mol-header-container-inner {
  background: linear-gradient(90deg, #F9F9F9, #F3F3F3 30%, #F3F3F3 70%, #F9F9F9);
}

/**
 icon buttons
 */
.mol-header-button-wrapper {
  cursor: pointer;
}

/* icons */
.mol-header-button-icon-wrapper {
  width: 35px;
  height: 35px;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
}

.mol-header-button-icon-wrapper.mol-header-button-cce-search {
  background: linear-gradient(90deg, white, rgba(255, 255, 255, 0.8) 65%, rgba(255, 255, 255, 0.1) 100%), url("../../images/mol/templ/cce_search_header_btn_bg.png") no-repeat center;
}

@media (min-width: 576px) {
  .mol-header-button-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 768px) {
  .mol-header-button-icon-wrapper {
    width: 40px;
    height: 40px;
  }
}

/* menu button */
@media (min-width: 768px) {
  .mol-header-button-menu:hover > .mol-header-button-icon-wrapper {
    background-color: #656565;
  }
}

.mol-header-button-menu > .mol-header-button-icon-wrapper {
  width: 22px;
  background: transparent;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .mol-header-button-menu > .mol-header-button-icon-wrapper {
    height: 36px;
    width: 36px;
    background-color: #858585;
    border: 0;
  }
}

/* search bar */
/*
.mol-search-button  {
  border: 1px solid #CED4DA !important;

  $color: $light;

  @if $enable-gradients {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  } @else {
    background-color: $color !important;
  }

  img {
    opacity: .8;
  }
}

.mol-search-button:hover  {
  img {
    opacity: 1;
  }

  $color: darken($light, 3%);

  @if $enable-gradients {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  } @else {
    background-color: $color !important;
  }
}
*/
.mol-footer-container {
  border-top: 1px solid #D9D9D9;
  background-color: #F8F8F8;
  box-shadow: 0 50vh 0 50vh #F8F8F8;
}

.mol-footer-container-inner {
  background: linear-gradient(90deg, #F8F8F8, #F2F2F2 30%, #F2F2F2 70%, #F8F8F8);
}

.mol-footer-container.mol-checkout-footer {
  border-top: 1px solid #D9D9D9;
  background-color: #F2F2F2;
  box-shadow: 0 50vh 0 50vh #F2F2F2;
}

.mol-footer-row {
  color: #777;
  border-top: 1px solid #E5E5E5;
  font-size: 0.929rem;
}

.mol-text-footer {
  color: #777;
  font-size: 0.857rem;
}

.mol-footer-heading {
  color: #777;
  font-weight: 700;
}

.mol-checkout-footer .mol-footer-heading,
.mol-checkout-merchant-card .mol-footer-heading {
  color: #888;
}

a.mol-footer-link {
  display: inline-block;
  margin-top: .4rem;
  color: #777;
  font-size: 0.857rem;
}

a.mol-footer-link > img {
  opacity: .3;
}

a.mol-footer-link:hover, a.mol-footer-link:focus {
  color: #515151;
}

a.mol-footer-link:hover > img, a.mol-footer-link:focus > img {
  opacity: .6;
}

.mol-checkout-footer a.mol-footer-link,
.mol-checkout-merchant-card a.mol-footer-link {
  color: #8f8f8f;
}

.mol-btn-scroll-to-top {
  margin-left: 25px;
}

.mol-btn-scroll-to-top img {
  opacity: .4;
}

.mol-btn-scroll-to-top:hover, .mol-btn-scroll-to-top:focus {
  text-decoration: none;
}

.mol-btn-scroll-to-top:hover img, .mol-btn-scroll-to-top:focus img {
  opacity: .7;
}

.mol-btn-scroll-to-top-icon {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
}

:root {
  --blue: #197EB7;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #D12A00;
  --orange: #E47911;
  --yellow: #ffc107;
  --green: #2F8A21;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #999999;
  --gray-dark: #343434;
  --primary: #197EB7;
  --secondary: #666666;
  --success: #2F8A21;
  --info: #17a2b8;
  --warning: #E47911;
  --danger: #D12A00;
  --light: #F2F2F2;
  --dark: #262626;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #262626;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #197EB7;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #105074;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #999999;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 1.286rem;
}

h2, .h2 {
  font-size: 1.286rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #E5E5E5;
}

small,
.small {
  font-size: 0.857rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999999;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 0.2rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #999999;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #E5E5E5;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #E5E5E5;
}

.table tbody + tbody {
  border-top: 2px solid #E5E5E5;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #E5E5E5;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #E5E5E5;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9F9F9;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfdbeb;
}

.table-hover .table-primary:hover {
  background-color: #acd0e5;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #acd0e5;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d4d4;
}

.table-hover .table-secondary:hover {
  background-color: #c7c7c7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c7c7c7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c5dec1;
}

.table-hover .table-success:hover {
  background-color: #b5d5b0;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b5d5b0;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7d9bc;
}

.table-hover .table-warning:hover {
  background-color: #f4cca5;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f4cca5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2c3b8;
}

.table-hover .table-danger:hover {
  background-color: #eeb1a2;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #eeb1a2;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb;
}

.table-hover .table-light:hover {
  background-color: #eeeeee;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #eeeeee;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c2c2;
}

.table-hover .table-dark:hover {
  background-color: #b5b5b5;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b5b5b5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #262626;
  border-color: #393939;
}

.table .thead-light th {
  color: #666666;
  background-color: #F2F2F2;
  border-color: #E5E5E5;
}

.table-dark {
  color: #fff;
  background-color: #262626;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #393939;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #262626;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #DFDFDF;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #262626;
  background-color: #fff;
  border-color: #65baea;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #F2F2F2;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #262626;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #262626;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 0.75rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #999999;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #DFDFDF;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #262626;
  background-color: #dfdfdf;
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #DFDFDF;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #DFDFDF;
  box-shadow: 0 0 0 1px rgba(223, 223, 223, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #DFDFDF;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #DFDFDF;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: white;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #f9f9f9;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(223, 223, 223, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #DFDFDF;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 1px rgba(223, 223, 223, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #D12A00;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #d12a00;
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #D12A00;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #D12A00;
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D12A00;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D12A00;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #ff7452;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff3705;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(209, 42, 0, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D12A00;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #197EB7;
  border-color: #197EB7;
}

.btn-primary:hover {
  color: #fff;
  background-color: #146795;
  border-color: #135f8a;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #197EB7;
  border-color: #197EB7;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #135f8a;
  border-color: #11577f;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #535353;
  border-color: #4d4d4d;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 1px rgba(102, 102, 102, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #464646;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(102, 102, 102, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #2F8A21;
  border-color: #2F8A21;
}

.btn-success:hover {
  color: #fff;
  background-color: #246b1a;
  border-color: #216117;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 1px rgba(47, 138, 33, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #2F8A21;
  border-color: #2F8A21;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #216117;
  border-color: #1d5715;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(47, 138, 33, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #E47911;
  border-color: #E47911;
}

.btn-warning:hover {
  color: #fff;
  background-color: #c0660e;
  border-color: #b5600d;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 121, 17, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #E47911;
  border-color: #E47911;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #b5600d;
  border-color: #a95a0d;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 121, 17, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #D12A00;
  border-color: #D12A00;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ab2200;
  border-color: #9e2000;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #D12A00;
  border-color: #D12A00;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #9e2000;
  border-color: #911d00;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.5);
}

.btn-light {
  color: #262626;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-light:hover {
  color: #262626;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 1px rgba(242, 242, 242, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #262626;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #262626;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(242, 242, 242, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.btn-dark:hover {
  color: #fff;
  background-color: #131313;
  border-color: #0d0d0d;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 1px rgba(38, 38, 38, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d0d0d;
  border-color: #060606;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(38, 38, 38, 0.5);
}

.btn-outline-primary {
  color: #197EB7;
  background-color: transparent;
  background-image: none;
  border-color: #197EB7;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #197EB7;
  border-color: #197EB7;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #197EB7;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #197EB7;
  border-color: #197EB7;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.5);
}

.btn-outline-secondary {
  color: #666666;
  background-color: transparent;
  background-image: none;
  border-color: #666666;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 1px rgba(102, 102, 102, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #666666;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(102, 102, 102, 0.5);
}

.btn-outline-success {
  color: #2F8A21;
  background-color: transparent;
  background-image: none;
  border-color: #2F8A21;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #2F8A21;
  border-color: #2F8A21;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 1px rgba(47, 138, 33, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #2F8A21;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #2F8A21;
  border-color: #2F8A21;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(47, 138, 33, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #E47911;
  background-color: transparent;
  background-image: none;
  border-color: #E47911;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #E47911;
  border-color: #E47911;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 121, 17, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #E47911;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #E47911;
  border-color: #E47911;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 121, 17, 0.5);
}

.btn-outline-danger {
  color: #D12A00;
  background-color: transparent;
  background-image: none;
  border-color: #D12A00;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #D12A00;
  border-color: #D12A00;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #D12A00;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #D12A00;
  border-color: #D12A00;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(209, 42, 0, 0.5);
}

.btn-outline-light {
  color: #F2F2F2;
  background-color: transparent;
  background-image: none;
  border-color: #F2F2F2;
}

.btn-outline-light:hover {
  color: #262626;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 1px rgba(242, 242, 242, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #F2F2F2;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #262626;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(242, 242, 242, 0.5);
}

.btn-outline-dark {
  color: #262626;
  background-color: transparent;
  background-image: none;
  border-color: #262626;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 1px rgba(38, 38, 38, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #262626;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(38, 38, 38, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #197EB7;
  background-color: transparent;
}

.btn-link:hover {
  color: #105074;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #999999;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #262626;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F2F2F2;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.3rem 1rem;
  clear: both;
  font-weight: 400;
  color: #262626;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #191919;
  text-decoration: none;
  background-color: #F2F2F2;
}

.dropdown-item.active, .dropdown-item:active {
  color: #191919;
  text-decoration: none;
  background-color: #F2F2F2;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #999999;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #999999;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.3rem 1rem;
  color: #262626;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #262626;
  text-align: center;
  white-space: nowrap;
  background-color: #F2F2F2;
  border: 1px solid #DFDFDF;
  border-radius: 0.2rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #197EB7;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #92cef0;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #999999;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #F2F2F2;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #E5E5E5;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #197EB7;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #197EB7;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 126, 183, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(25, 126, 183, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #197EB7;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 126, 183, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #262626;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343434' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #DFDFDF;
  border-radius: 0.2rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #65baea;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(101, 186, 234, 0.5);
}

.custom-select:focus::-ms-value {
  color: #262626;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #999999;
  background-color: #F2F2F2;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #65baea;
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #65baea;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #F2F2F2;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #262626;
  background-color: #fff;
  border: 1px solid #DFDFDF;
  border-radius: 0.2rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #262626;
  content: "Browse";
  background-color: #F2F2F2;
  border-left: 1px solid #DFDFDF;
  border-radius: 0 0.2rem 0.2rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #197EB7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #92cef0;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E5E5E5;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #197EB7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #92cef0;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E5E5E5;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 1px;
  margin-left: 1px;
  background-color: #197EB7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #92cef0;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #E5E5E5;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #E5E5E5;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #999999;
}

.nav-tabs {
  border-bottom: 1px solid #E5E5E5;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #F2F2F2 #F2F2F2 #E5E5E5;
}

.nav-tabs .nav-link.disabled {
  color: #999999;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #666666;
  background-color: #fff;
  border-color: #E5E5E5 #E5E5E5 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #197EB7;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.2rem - 1px) calc(0.2rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.2rem - 1px) calc(0.2rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.2rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.2rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.2rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #999999;
  content: "";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #999999;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.2rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #262626;
  background-color: #fff;
  border: 1px solid #DFDFDF;
}

.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #F2F2F2;
  border-color: #E5E5E5;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(25, 126, 183, 0.1);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #262626;
  background-color: transparent;
  border-color: transparent;
}

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #E5E5E5;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #197EB7;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #135f8a;
}

.badge-secondary {
  color: #fff;
  background-color: #666666;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #4d4d4d;
}

.badge-success {
  color: #fff;
  background-color: #2F8A21;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #216117;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #fff;
  background-color: #E47911;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #b5600d;
}

.badge-danger {
  color: #fff;
  background-color: #D12A00;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #9e2000;
}

.badge-light {
  color: #262626;
  background-color: #F2F2F2;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #262626;
  text-decoration: none;
  background-color: #d9d9d9;
}

.badge-dark {
  color: #fff;
  background-color: #262626;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0d0d0d;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.2rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #197eb7;
  background-color: #f6fafc;
  border-color: #e3f0f6;
}

.alert-primary hr {
  border-top-color: #d0e6f0;
}

.alert-primary .alert-link {
  color: #135f8a;
}

.alert-secondary {
  color: #666666;
  background-color: #f9f9f9;
  border-color: #ededed;
}

.alert-secondary hr {
  border-top-color: #e0e0e0;
}

.alert-secondary .alert-link {
  color: #4d4d4d;
}

.alert-success {
  color: #2f8a21;
  background-color: #f7faf6;
  border-color: #e6f1e4;
}

.alert-success hr {
  border-top-color: #d6e8d3;
}

.alert-success .alert-link {
  color: #216117;
}

.alert-info {
  color: #17a2b8;
  background-color: #f6fbfc;
  border-color: #e3f4f6;
}

.alert-info hr {
  border-top-color: #d0ecf0;
}

.alert-info .alert-link {
  color: #117a8b;
}

.alert-warning {
  color: #e47911;
  background-color: #fefaf5;
  border-color: #fcefe2;
}

.alert-warning hr {
  border-top-color: #fae2cb;
}

.alert-warning .alert-link {
  color: #b5600d;
}

.alert-danger {
  color: #d12a00;
  background-color: #fdf6f5;
  border-color: #f9e5e0;
}

.alert-danger hr {
  border-top-color: #f5d3cb;
}

.alert-danger .alert-link {
  color: #9e2000;
}

.alert-light {
  color: #f2f2f2;
  background-color: #fefefe;
  border-color: #fdfdfd;
}

.alert-light hr {
  border-top-color: #f0f0f0;
}

.alert-light .alert-link {
  color: #d9d9d9;
}

.alert-dark {
  color: #262626;
  background-color: #f6f6f6;
  border-color: #e5e5e5;
}

.alert-dark hr {
  border-top-color: #d8d8d8;
}

.alert-dark .alert-link {
  color: #0d0d0d;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 15px;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (15px * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (15px * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #F2F2F2;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #F2F2F2;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 960px;
    margin: 15px auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (15px * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (15px * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 940px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #666666;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #666666;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #666666;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #666666;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #666666;
  border-radius: 0.2rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 14px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.2rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.2rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.2rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #262626;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #197EB7 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #135f8a !important;
}

.bg-secondary {
  background-color: #666666 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4d4d4d !important;
}

.bg-success {
  background-color: #2F8A21 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #216117 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #E47911 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #b5600d !important;
}

.bg-danger {
  background-color: #D12A00 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9e2000 !important;
}

.bg-light {
  background-color: #F2F2F2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d9d9d9 !important;
}

.bg-dark {
  background-color: #262626 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d0d0d !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #DFDFDF !important;
}

.border-top {
  border-top: 1px solid #DFDFDF !important;
}

.border-right {
  border-right: 1px solid #DFDFDF !important;
}

.border-bottom {
  border-bottom: 1px solid #DFDFDF !important;
}

.border-left {
  border-left: 1px solid #DFDFDF !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #197EB7 !important;
}

.border-secondary {
  border-color: #666666 !important;
}

.border-success {
  border-color: #2F8A21 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #E47911 !important;
}

.border-danger {
  border-color: #D12A00 !important;
}

.border-light {
  border-color: #F2F2F2 !important;
}

.border-dark {
  border-color: #262626 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.2rem !important;
}

.rounded-top {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.rounded-right {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-left {
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-45 {
  margin: 2rem !important;
}

.mt-45,
.my-45 {
  margin-top: 2rem !important;
}

.mr-45,
.mx-45 {
  margin-right: 2rem !important;
}

.mb-45,
.my-45 {
  margin-bottom: 2rem !important;
}

.ml-45,
.mx-45 {
  margin-left: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-45 {
  padding: 2rem !important;
}

.pt-45,
.py-45 {
  padding-top: 2rem !important;
}

.pr-45,
.px-45 {
  padding-right: 2rem !important;
}

.pb-45,
.py-45 {
  padding-bottom: 2rem !important;
}

.pl-45,
.px-45 {
  padding-left: 2rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-45 {
    margin: 2rem !important;
  }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 2rem !important;
  }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 2rem !important;
  }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 2rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-45 {
    padding: 2rem !important;
  }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 2rem !important;
  }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 2rem !important;
  }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 2rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-45 {
    margin: 2rem !important;
  }
  .mt-md-45,
  .my-md-45 {
    margin-top: 2rem !important;
  }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 2rem !important;
  }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 2rem !important;
  }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 2rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-45 {
    padding: 2rem !important;
  }
  .pt-md-45,
  .py-md-45 {
    padding-top: 2rem !important;
  }
  .pr-md-45,
  .px-md-45 {
    padding-right: 2rem !important;
  }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 2rem !important;
  }
  .pl-md-45,
  .px-md-45 {
    padding-left: 2rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-45 {
    margin: 2rem !important;
  }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 2rem !important;
  }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 2rem !important;
  }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 2rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-45 {
    padding: 2rem !important;
  }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 2rem !important;
  }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 2rem !important;
  }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 2rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-45 {
    margin: 2rem !important;
  }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 2rem !important;
  }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 2rem !important;
  }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 2rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-45 {
    padding: 2rem !important;
  }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 2rem !important;
  }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 2rem !important;
  }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 2rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #197EB7 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #135f8a !important;
}

.text-secondary {
  color: #666666 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #4d4d4d !important;
}

.text-success {
  color: #2F8A21 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #216117 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #E47911 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b5600d !important;
}

.text-danger {
  color: #D12A00 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #9e2000 !important;
}

.text-light {
  color: #F2F2F2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #262626 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important;
}

.text-body {
  color: #262626 !important;
}

.text-muted {
  color: #999999 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*# sourceMappingURL=bootstrap.css.map */