/**
 * container
 */
.mol-product-listing-container {
 border-bottom: 1px solid $gray-300;
}

@include media-breakpoint-up(sm) {
  .mol-product-listing-container {
    margin-left: -10px !important;
    border-bottom: none;
  }
}

.mol-product-listing-item {
    width: 100%;
		max-width: 100%; // need in IE 10 for correct flex item sizing
}

@include media-breakpoint-up(sm) {
  .mol-product-listing-item {
      padding-left: 10px;
      padding-bottom: 11px;
  }
}

.mol-category-bestseller-listing-item .mol-product-listing-container,
.mol-shop-listing-item-container .mol-product-listing-container {
  margin-left: -1rem !important;
  border-bottom: none;
}

@include media-breakpoint-up(lg) {
  .mol-shop-listing-item-container .mol-product-listing-container {
    // note:
    // if we change the width, we also need to change
    // the max-width of .mol-shop-card-categories-block
    width: 330px; // needed in IE
    max-width: 330px;
  }
}

.mol-category-bestseller-listing-item .mol-product-listing-item,
.mol-shop-listing-item .mol-product-listing-item {
  width: auto;
	padding-left: 1rem;
  padding-bottom: 0;
}

/**
 * product cards
 */
.mol-product-card {
  position: relative;
  padding:1rem 0;
  margin: 0 0;
  width: 100%;
  color: $body-color;
  border-top: 1px solid $gray-300;
  background-color: $white;
  background: $white;

  &:hover,
  &:focus  {
    color: darken($primary, 15%);
    text-decoration: none;
    outline: 0;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-card {

    padding: 1rem 1rem;
    border-top: none;
    box-shadow: 0 0 0 1px $gray-300, 0px 2px 2px 0 $gray-300;
    border-radius: $border-radius;
    background: linear-gradient(0deg, #fafafa, #fff 30%);

    &:hover,
    &:focus  {
      color: darken($primary, 15%);
      text-decoration: none;
      transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
      box-shadow: 0 0 0 1px $gray-500, 0 2px 2px 0 $gray-500;
    }
  }
}

@include media-breakpoint-up(xl) {
  .mol-product-card {
    padding: 1.25rem;
    box-shadow: 0 0 0 1px $gray-275, 0px 2px 2px 0 $gray-300;
    //background: linear-gradient(0deg, #fafafa, #fff 40%);

    &:hover,
    &:focus  {
      transition: border .3s ease-in-out, box-shadow .2s ease-in-out;
      box-shadow: 0 0 0 1px $gray-500, 0 2px 2px 0 $gray-500;
    }
    //padding-left: 1.5rem;
    //padding-right: 1.5rem;
  }
}

// image

.mol-product-card-img {
  border:1px solid transparent;
  width: 100px;
  max-height: 150px;

  & img {
    flex: 0 0 auto; // makes IE 11 resizing the img-width correctly
    //flex: 1 1 0;
    object-fit:contain;
    width: auto;
    max-height: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-card-img {
    height:170px;
    max-height: none;
    width: auto;
  }
}

@include media-breakpoint-up(xl) {
  .mol-product-card-img {
    height:190px;
  }
}

// text block container

.mol-product-card-text-block {
  padding-left: 1rem;
  min-width: 1px; // needed in IE 11 for correct flex item sizing
  -ms-flex-negative: 1; // needed in IE 10 for correct flex item sizing
  //flex-shrink: 1;
}

@include media-breakpoint-up(sm) {
  .mol-product-card-text-block {
    padding-left: 0;
  }
}

// name

// name-outer and name-inner are used,
// when we want the variantinfo block inside
// the streched/grown flex-item
.mol-product-card-name-outer {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

@include media-breakpoint-up(md) {
  .mol-product-card-name-outer {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

.mol-product-card-name-inner {
  word-wrap: break-word;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-card-name-inner {
    margin-top: .5rem;
  }
}

@include media-breakpoint-up(xl) {
  .mol-product-card-name-inner {
    margin-top: 1rem;
  }
}

.mol-product-card-name-variant {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: $small-font-size;
  color: $text-muted;
}

// default name div as streched/grown flex-item
.mol-product-card-name {
  -ms-flex-positive: 0;
  flex-grow: 0;
  word-wrap: break-word;
  overflow: hidden;
  //line-height: 1.4;

  &:hover {
    text-decoration: underline;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-card-name {
    margin-top: .5rem;
  }
}

@include media-breakpoint-up(md) {
  .mol-product-card-name {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@include media-breakpoint-up(xl) {
  .mol-product-card-name {
    margin-top: 1rem;
  }
}

// energy-efficiency

.mol-product-card-energy-efficiency {
  margin-top: .25rem; // compensate different line-heights
  font-size: $small-font-size;
  color: $text-muted;
}


// price

.mol-product-card-price {
  margin-top: .5rem;
  font-size: $mol-larger-font-size;
  line-height: 1.4;
  color: $body-color;
  font-weight: $font-weight-semi-bold;
}

@include media-breakpoint-up(sm) {
  .mol-product-card-price {
    margin-top: .75rem;
    line-height: 1.3;
  }
}

.mol-product-card-offer-price-rel {
  margin-top: .5rem;
  font-size: $mol-smaller-font-size;
  color: $body-color;

  & > .mol-product-card-offer-price-rel-text {
    display: inline;
    text-decoration: line-through;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-card-offer-price-rel {
    margin-top: .75rem;
  }
}

.mol-product-card-offer-price {
  font-size: $mol-larger-font-size;
  line-height: 1.3;
  color: $mol-red-dark;
  font-weight: $font-weight-semi-bold;
}

@include media-breakpoint-up(sm) {
  .mol-product-card-offer-price {
    line-height: 1.2;
  }
}

.mol-product-card-offer-price-mark {
  position:absolute;
  top: 1rem;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: $border-radius;
  box-shadow: 1px 1px 3px $gray-400;
  height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: $font-weight-semi-bold;
  color: $mol-red-dark;

}

@include media-breakpoint-up(sm) {
  .mol-product-card-offer-price-mark {
    top: 1rem;
    left: 1rem;
    height: 26px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

// unit price

.mol-product-card-unit-price {
  //margin-top: 1px; // compensate different line-heights
  font-size: $small-font-size;
  color: $text-muted;
}

// shipping cost

.mol-product-card-shipping-cost {
  font-size: $small-font-size;
  color: $text-muted;
}

/****************************************
 ****************************************
 * product card adaption for carousels
 ****************************************
 */

.mol-product-carousel-item > .mol-product-card {

  padding: 1rem 1rem;
  border-radius: $border-radius;
  // border / no box-shadow (shadows will be invisible when overflowing the slick track)
  box-shadow: none;
  border:1px solid $gray-300;
  background: linear-gradient(0deg, #FBFBFB, #fff 30%);

  .mol-start-recommended-products & {
    background: linear-gradient(0deg, #FAFAFA, #fff 30%);
  }

  &:hover,
  &:focus  {
    color: darken($primary, 15%);
    text-decoration: none;
    transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
    border:1px solid $gray-500;
    box-shadow: 0 1px 1px 0 $gray-400;
    outline: 0;
  }

  & > .mol-product-card-img {
    height:120px;
    max-height: none;
    width: auto;
  }

  & > .mol-product-card-name {
    // margin-top at xs
    margin-top: .5rem;
    font-size: $mol-smaller-font-size;
    line-height: 1.4;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  & > .mol-product-card-name-outer {
    // margin-top at xs
    margin-top: .5rem;
    font-size: $mol-smaller-font-size;
    line-height: 1.4;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }

  & > .mol-product-card-price {
    font-size: 1rem;
    margin-top: .5rem;
    font-weight: $font-weight-semi-bold;
  }

  & > .mol-product-card-offer-price-rel {
    margin-top: .5rem;
  }

  & > .mol-product-card-offer-price {
    font-size: 1rem;
    font-weight: $font-weight-semi-bold;
  }
}

@include media-breakpoint-up(sm) {
  .mol-product-carousel-item > .mol-product-card {
    & > .mol-product-card-img {
      height:120px;
      padding-left:10%;
      padding-right: 10%;
    }
  }
}

@include media-breakpoint-up(md) {
  .mol-product-carousel-item > .mol-product-card {

    & > .mol-product-card-name {
      font-size: 1rem;
    }

    & > .mol-product-card-name-outer {
      font-size: 1rem;
    }

    & > .mol-product-card-price {
      margin-top: .75rem;
    }

    & > .mol-product-card-price {
      margin-top: .75rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .mol-product-carousel-item > .mol-product-card {

    border:1px solid $gray-250; //$mol-border-color-light;
    padding: 1rem 1.25rem;

    & > .mol-product-card-img {
      height:130px;
    }

    .mol-start-recommended-products & {

      border:1px solid $gray-275;

      &:hover,
      &:focus  {
        color: darken($primary, 15%);
        text-decoration: none;
        transition: border .3s ease-in-out, box-shadow .1s ease-in-out;
        border:1px solid $gray-500;
        box-shadow: 0 1px 1px 0 $gray-400;
        outline: 0;
      }
    }
  }
}

/**********************************************
 **********************************************
 * product card adaption for catggory bestseller listings
 **********************************************
 */

.mol-category-bestseller-listing-item .mol-product-card,
.mol-shop-listing-item .mol-product-card {
  color: $body-color; //$secondary;
  padding: 0 0;
  border-top: none;
  box-shadow: none;
  background: $white;

  &:hover,
  &:focus  {
    color: darken($primary, 15%);
    text-decoration: none;
    box-shadow: none;
  }

  & > .mol-product-card-img {
    align-self: center !important;
    height:130px;
    max-height: none;
    width: auto;
    max-width: 140px;

    & > img {
      flex: 0 0 auto; // makes IE 11 resizing the img-width correctly
      max-height: 100%;
      max-width: 100%;
    }
  }

  & > .mol-product-card-text-block {

    padding-left: 0;
    text-align: center;
    color: inherit;

    & > .mol-product-card-name {
      -ms-flex-positive: 0;
      flex-grow: 0;
      margin-top: .5rem;
      font-size: $mol-small-font-size;
      line-height: 1.4;
    }

    & > .mol-product-card-price {
      color: $body-color; //$secondary;
      font-size: 1rem;
      margin-top: .5rem;
      font-weight: $font-weight-semi-bold;
    }

    & > .mol-product-card-offer-price-rel {
      color: $secondary;
      margin-top: .5rem;
    }

    & > .mol-product-card-offer-price {
      color: $body-color; //$secondary;
      font-size: 1rem;
      font-weight: $font-weight-semi-bold;
    }

    & > .mol-product-card-unit-price {
      margin-top: 1px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .mol-category-bestseller-listing-item .mol-product-card,
  .mol-shop-listing-item .mol-product-card {
    & > .mol-product-card-text-block {
      & > .mol-product-card-name {
        font-size: $mol-smaller-font-size;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .mol-category-bestseller-listing-item .mol-product-card,
  .mol-shop-listing-item .mol-product-card {
    padding: 0 .5rem;
  }
}

@include media-breakpoint-up(lg) {
  .mol-category-bestseller-listing-item .mol-product-card,
  .mol-shop-listing-item .mol-product-card {
    padding: 0 1rem;

    & > .mol-product-card-img {
      align-self: center !important;
      height:130px;
      width: auto;
      max-width: 140px;
    }
  }

  .mol-category-bestseller-listing-item .mol-product-card {
    & > .mol-product-card-text-block {
      & > .mol-product-card-name {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
}

/*@include media-breakpoint-up(lg) {
  .mol-category-bestseller-listing-item .mol-product-card {
    & > .mol-product-card-text-block {
      & > .mol-product-card-name {
        font-size: 1rem;
      }
    }
  }
}*/

/**********************************************
 **********************************************
 * product card adaption for sidebar listings
 **********************************************
 */
/*
.mol-sidebar-product-listing-item > .mol-product-card {
  padding: 1rem 0rem;
  border-radius: $border-radius;
  box-shadow: none;
  border: none;

  & > .mol-product-card-img {
    border:1px solid transparent;
    width: 90px;
    height: auto;

    & > img {
      flex: 0 0 auto; // makes IE 11 resizing the img-width correctly
      max-height: 100%;
    }
  }

  & > .mol-product-card-text-block {
    padding-left: 1rem;

    & > .mol-product-card-name {
      margin-top: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      font-size: $mol-smaller-font-size;
    }

    & > .mol-product-card-price {
      margin-top: .25rem;
      font-size: 1rem;
      line-height: 1.4;
    }
  }
}
*/
