/**
 * fonts
 */
 /* roboto-300 - latin */
 @font-face {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 300;
   src: url('../fonts/roboto-v18-latin-300.eot'); /* IE9 Compat Modes */
   src: local('Roboto Light'), local('Roboto-Light'),
        url('../fonts/roboto-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/roboto-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/roboto-v18-latin-300.woff') format('woff'), /* Modern Browsers */
        url('../fonts/roboto-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/roboto-v18-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
 }
 /* roboto-regular - latin */
 @font-face {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   src: url('../fonts/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
   src: local('Roboto'), local('Roboto-Regular'),
        url('../fonts/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
 }
 /* roboto-500 - latin */
 @font-face {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   src: url('../fonts/roboto-v18-latin-500.eot'); /* IE9 Compat Modes */
   src: local('Roboto Medium'), local('Roboto-Medium'),
        url('../fonts/roboto-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/roboto-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/roboto-v18-latin-500.woff') format('woff'), /* Modern Browsers */
        url('../fonts/roboto-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/roboto-v18-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
 }
 /* roboto-700 - latin */
 @font-face {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 700;
   src: url('../fonts/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
   src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
        url('../fonts/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
 }

// font styles
html {
  font-size: $mol-base-font-size-mobile !important; // 15
}
@include media-breakpoint-up(md) {
  html {
    font-size: $mol-base-font-size !important; // 14
  }
}

// headlines
h1, h2, h3 {

  &.mol-unstyled {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    display: inline;
  }

  &.mol-unstyled-semi-bold {
    font-size: 1rem;
    font-weight: $font-weight-semi-bold;
    line-height: 1.5;
    display: inline;
  }

  &.mol-section-heading {
    //font-weight: $font-weight-semi-bold;
    margin-top: 0;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  }

  &.mol-product-listing-heading,
  &.mol-category-listing-heading,
  &.mol-sections-top-heading {
    font-size: $mol-large-font-size !important;
    font-weight: normal !important;
    line-height: 1.4 !important;
  }

  &.mol-category-heading {
    font-size: $mol-large-font-size !important;
    font-weight: $font-weight-semi-bold !important;
    line-height: 1.4 !important;
  }

  &.mol-category-heading-2 {
    color: $mol-blue-dark;
  }
}

@include media-breakpoint-up(md) {
  h1, h2 {
    &.mol-section-heading {
      margin-bottom: 2rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  h1, h2 {
    font-size: $mol-larger-font-size !important; // actually smaller at xl

    &.mol-section-heading {
      font-size: $mol-larger-font-size !important; // actually smaller at xl
    }

    &.mol-product-listing-heading,
    &.mol-category-listing-heading,
    &.mol-sections-top-heading {
      font-size: $mol-large-font-size !important; // not smaller at xl
    }

    &.mol-category-heading {
      font-size: $mol-larger-font-size !important; // actually smaller at xl
    }
  }
}

// headline container

.mol-category-listing-heading-container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-up(xl) {
  .mol-category-listing-heading-container {
    margin-top: 2rem; //.25rem; //2rem;
    margin-bottom: 1.5rem;
    //padding-left: 1.5rem;
    //border-left: 4px solid #ececec;

    //background: linear-gradient(90deg, transparent, transparent 90%, #fff), linear-gradient(#fff, #fbfbfb);
    //box-shadow: -1px 2px 2px 0 #ECECEC;
    //border-bottom: 1px solid $gray-250;
    //box-shadow: 0 1px 4px 0 #ECECEC;
    //border-radius: .3rem;
    //padding-bottom: 10px;
    //padding-left: 1.5rem;
    //padding-top: 1rem;
    //margin-top: 1rem;
    //margin-bottom: 20px;
  }
}

.mol-product-listing-heading-container {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

@include media-breakpoint-up(sm) {
  .mol-product-listing-heading-container {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/*@include media-breakpoint-up(md) {
  .mol-product-listing-heading-container {
    margin-top: 0;
    margin-bottom: 0;
    //border-bottom: 1px solid $mol-border-color-light;
  }
}*/

@include media-breakpoint-up(xl) {
  .mol-product-listing-heading-container {
    //padding-bottom: 1rem;
    margin-top: 2rem; //.25rem;
    margin-bottom: 1.5rem;
  }
}

.mol-left-menu-section-heading {
  // xl only, will be offcanvas on smaller breakpoints
  font-size: $mol-bit-larger-font-size;
  max-width: 220px;
  line-height: 1.5;
  //text-transform: uppercase;
  //border-top: 1px solid $gray-250 !important;
  padding-top: 1.5rem !important; //1.3rem !important;
  padding-bottom: 0 !important; //.75rem;
  margin-bottom: 1rem; //.75rem;
  color: #777 !important; //#666 !important;

  & a {
    color: #666 !important;

    &:hover {
      color: darken(#666, 15%);
    }
  }
  /*& a.mol-shop-start-page {
    color: #666 !important;
    font-size: $mol-smaller-font-size;
    //font-weight: $font-weight-semi-bold;

    &:hover {
      text-decoration: none;
      color: darken(#666, 15%) !important;

      & .mol-text-hover-underline {
        text-decoration: underline;
      }
    }
  }*/

  /*&:not(.mol-offcanvas-sub-head) {
    margin-top: 2px;
  }*/
}

// sizes
b,
strong  {
  font-weight: $font-weight-semi-bold !important;
}
.smaller-text {
  font-size: $mol-smaller-font-size !important; // 13
}

.small-text {
  font-size: $mol-small-font-size !important; // 12
}

.xs-text {
  font-size: $mol-xs-font-size !important; // 12
}

.bit-larger-text {
  font-size: $mol-bit-larger-font-size !important; // 16
}

.larger-text {
  font-size: $mol-larger-font-size !important; // 16
}

.large-text {
  font-size: $mol-large-font-size !important; // 20
}

.semi-bold {
  font-weight: $font-weight-semi-bold;
}

.bold {
  font-weight: $font-weight-bold;
}
.thin {
  font-weight: $font-weight-light;
}

// decoration

.mol-text-underline
{
	text-decoration: underline;
}

// colors
.mol-text-dark-blue {
  color: $mol-blue-dark !important;
}

.mol-text-blue-secondary {
  color: $mol-blue-secondary !important;
}

.mol-text-orange {
  color: $orange;
}

a.mol-text-orange {
  &:hover {
    color: darken($orange, 10%);
  }
}

@include media-breakpoint-up(md) {
  .large-text-md {
    font-size: $mol-large-font-size !important; // 20
  }
}
