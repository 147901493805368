/**
 * product listingl filter selection box
 */

.mol-pl-filter-box {
  /*@include media-breakpoint-up(xl) {
    padding: .25rem 0;
  }*/
}

.mol-pl-filter-box-head {
  padding-bottom: 1rem;
  padding-top: 1rem;

  & > .mol-pl-filter-box-head-btn {

      color: $body-color;
      //font-size: $mol-bit-larger-font-size;

      @include media-breakpoint-up(xl) {
        //font-weight: $font-weight-semi-bold;
        font-size: $mol-bit-larger-font-size;
        color: #777;
        //padding: .25rem 0;
      }

      & > img {

        height: auto;
        width: 1rem;
        opacity:.6;

        @include media-breakpoint-up(xl) {
          height: 7px;
          width: auto;
          opacity:0;
        }
      }

      &:hover {
        text-decoration: none;

        & > img {
          opacity: .6;

          @include media-breakpoint-up(xl) {
            opacity: 0;
            //color: darken(#777, 15%);
          }
        }

      }

      &:focus {
        text-decoration: none;
      }
  }
}

.mol-pl-filter-box-body {
  //margin-bottom: 1.5rem;

  @include media-breakpoint-up(xl) {
    //margin-bottom: 1rem;
  }
}

// checkbox element

.mol-pl-filter-checkbox-elements-container {
  margin-top: -.5rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(xl) {
    margin-bottom: 1rem;
  }

  transition: max-height .2s ease;

  &.mol-preview-box {
    max-height:132px;
    overflow-y: hidden;
  }

  &.mol-scroll-box {
    max-height:184px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; // momentum scroll on iOS
  }
}

.mol-pl-filter-box-checkbox-element,
.mol-pl-filter-box-checkbox-element-active,
.mol-pl-filter-box-checkbox-element-deactivated {
  color: $secondary;
  margin-top: .5rem !important;

  @include media-breakpoint-up(xl) {
    font-size: $mol-smaller-font-size;
    //margin-top: .3rem !important;
  }

  &:hover {
    color: darken($secondary, 15%);
  }
}

.mol-pl-filter-box-checkbox-element-active
{
  color: $body-color;
  font-weight: $font-weight-semi-bold;
}

.mol-pl-filter-box-checkbox-element-deactivated
{
  color: $text-muted;
  opacity: .65 !important;

  &:hover {
    color: $text-muted;
    text-decoration: none;
  }
}

// color buttons
.mol-pl-filter-color-container {
  margin-top: -.75rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(xl) {
    padding-left: 3px;
    margin-top: -.375rem;
    margin-bottom: 1rem;
  }
}

.mol-pl-filter-color-btn, .mol-pl-filter-color-btn-active, .mol-pl-filter-color-btn-deactivated {
  color: $body-color !important;
  background: $white !important;
  border: 1px solid $gray-500 !important;
  padding: 0px !important;
  border-radius:.3rem !important;
  margin-top:.75rem !important;
  margin-right:.5rem !important;
  height: 3rem !important;
  width: 3rem !important;

  @include media-breakpoint-up(xl) {
    border-radius:50% !important;
    margin-top:.5rem !important;
    margin-right:.35rem !important;
    height: 1.6rem !important;
    width: 1.6rem !important;
  }

  &:hover,
  &.hover {
    box-shadow: 0px 0px 0 1px #fff,  0px 0px 0 2px #999999 !important
  }

  &:focus,
  &.focus  {
    box-shadow: 0px 0px 0 1px #fff,  0px 0px 0 2px #999999 !important
  }

  &.mol-black {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #262626 !important;
    border: 1px solid #000 !important;
  }

  &.mol-gray {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #999 !important;
    border: 1px solid #999 !important;
  }

  &.mol-white {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.025) 40%, rgba(0, 0, 0, 0) 80%), #fff !important;
    border: 1px solid $gray-500 !important;
  }

  &.mol-red {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #d42929 !important; //#c00 !important;
    border: 1px solid #d42929 !important; //#c00 !important;
  }

  &.mol-orange {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #fb940b !important;
    border: 1px solid #fb940b !important;
  }

  &.mol-yellow {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.03) 40%, rgba(0, 0, 0, 0) 80%), #ffff00 !important;
    border: 1px solid $gray-400 !important; //border: 1px solid $gray-400 !important;
  }

  &.mol-green {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #3bb63b !important; //#0c0 !important;
    border: 1px solid #3bb63b !important; //#0c0 !important;
  }

  &.mol-blue {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #1784b9 !important; //#00f !important;
    border: 1px solid #1784b9 !important; //#00f !important;
  }

  &.mol-violet {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #803bad !important; //#762ca7 !important;
    border: 1px solid #803bad !important; //#762ca7 !important;
  }

  &.mol-pink {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #ff98bf !important;
    border: 1px solid #ff98bf !important;
  }

  &.mol-brown {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.05) 40%, rgba(0, 0, 0, 0) 80%), #885418 !important;
    border: 1px solid #885418 !important;
  }

  &.mol-beige {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.03) 40%, rgba(0, 0, 0, 0) 80%), #f2e2d6 !important;
    border: 1px solid $gray-400 !important; //border: 1px #e2c0a5 !important;
  }

  &.mol-silver {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.04)), #c0c0c0 !important;
    border: 1px solid $gray-400 !important;
  }

  &.mol-gold {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 60%, rgba(0, 0, 0, 0.04)), #e8cd62 !important;
    border: 1px solid $gray-400 !important;
  }
}

.mol-pl-filter-color-btn-active {
  box-shadow: 0px 0px 0 2px #fff,  0px 0px 0 3px #666 !important
}

.mol-pl-filter-color-btn-deactivated {
  display: none !important;
}

// size buttons
.mol-pl-filter-sizes-container {
  margin-top: -.75rem;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(xl) {
    //padding-left: 3px;
    margin-top: -.15rem;
    margin-bottom: 1rem;
  }
}

.mol-pl-filter-size-btn, .mol-pl-filter-size-btn-active, .mol-pl-filter-size-btn-deactivated {
  color: $secondary !important;
  //background: $white !important;
  border: 1px solid $gray-500 !important;
  border-radius: .3rem !important;
  margin-top: .75rem !important;
  margin-right: .5rem !important;
  min-width: 3rem;
  padding: .3rem .45rem !important;
  background: linear-gradient(0deg,rgba(255,255,255,.8),rgba(255,255,255,0) 80%), $gray-100 !important;

  @include media-breakpoint-up(xl) {
    margin-top: .375rem !important;
    margin-right: .1rem !important;
    padding: .2rem .45rem !important;
    font-size: $mol-smaller-font-size !important;
  }

  &:hover,
  &.hover {
    border: 1px solid $gray-600 !important;
    background: linear-gradient(0deg,rgba(255,255,255,.8),rgba(255,255,255,0) 80%), $gray-200 !important;
  }

  &:focus,
  &.focus  {
    border: 1px solid $gray-600 !important;
    background: linear-gradient(0deg,rgba(255,255,255,.8),rgba(255,255,255,0) 80%), $gray-200 !important;
    box-shadow: 0 0 0 1px $gray-200 !important;
  }
}

.mol-pl-filter-size-btn-active {
  color: $body-color !important;
  font-weight: $font-weight-semi-bold !important;
  border: 1px solid $gray-600 !important;
  background: linear-gradient(0deg,rgba(255,255,255,.4),rgba(255,255,255,0) 80%), $gray-250 !important;
}

.mol-pl-filter-size-btn-deactivated {
  color: $text-muted !important;
  border: 1px solid $gray-200 !important;
  background: $gray-100 !important;
  cursor: default !important;

  &:hover,
  &:focus {
    border: 1px solid $gray-200 !important;
    background: $gray-100 !important;
  }
}

/**
 * Filter tags
 */
.mol-pl-filter-tags-container {
  width: 100%;
  overflow-x:auto;
}

.mol-btn-filter-tag, .mol-btn-filter-tag-active, .mol-btn-filter-tag-delete-all, {
  color: $body-color !important;
  font-size: $mol-small-font-size !important;
  background: $white !important;
  border: 1px solid $gray-400 !important;
  border-radius:.5rem !important;

  @include media-breakpoint-up(md) {
    font-size: $mol-smaller-font-size !important;
  }

  & > img {
    height: .6rem;
    opacity: .6;
  }

  &:hover,
  &.hover {
    border: 1px solid $gray-500 !important;
    background-color: $gray-100 !important;

    & > img {
      opacity: 1;
    }
  }

  &:focus,
  &.focus  {
    border: 1px solid $gray-500 !important;
    background-color: $gray-200 !important;
    box-shadow: 0 0 0 1px $gray-200 !important;
  }
}

.mol-btn-filter-tag-active {
  background: #e6f1f7 !important;

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    background-color: darken(#e6f1f7, 2%) !important;
  }
}

.mol-btn-filter-tag-delete-all {
  background: $gray-100 !important;

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    background-color: darken($gray-100, 2%) !important;
  }
}
