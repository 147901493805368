/**
 * Pagination
 */

.page-link {
  & > img {
    height: 13px;
    opacity: .8;
  }

  .mol-next:not(.disabled) &,
  .mol-prev:not(.disabled) & {
    background: linear-gradient(180deg, #fff, #fff 40%, $gray-150);

    @include media-breakpoint-up(md) {
      background: $white;
    }

    &:hover {
      background: $pagination-hover-bg;
    }
  }

}


.page-item {

  &:not(.active):not(.mol-prev):not(.mol-next){
    display:none;
  }

  &.disabled .page-link {
    & > img {
      //display: none;
      opacity: .5;
    }
  }
}

@include media-breakpoint-up(md) {

  &.active .page-link {
    z-index: 3 !important;
    color: $component-active-color !important;
    background-color: darken($component-active-bg, 7%) !important;
    border-color: darken($component-active-bg, 7%) !important;
  }

  .page-item {
    &:not(.active):not(.mol-prev):not(.mol-next){
      display: list-item;
    }
  }
}
