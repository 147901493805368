.mol-merchant-mop-button {
  font-size: $mol-small-font-size;
  padding-top:8px;
  width: 97px;
  border-radius:4px;
  border: 1px solid $mol-border-color-light;

  &:hover {
    background-color: $gray-100;
    //border: 1px solid $gray-300;
  }
}

.mol-merchant-mop-logo {
  height: 20px;
}
