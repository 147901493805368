/**
 * Modal customization
 */
body.modal-open {
  // realy kill scroll and browserbar toggle in Chrome and Safari (hopefully)
  // note: this would scroll the page to top when opening a modal
  //position: fixed;
  //width: 100%;
  //height: 100%;
}

// kill the scroll on the body
// note: this scrolls the body back to top
body.mol-modal-open-body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal {

  // disable pinch to zoom in modals (dosn't work in safari)
  //touch-action: none;

  // we do not want padding to simulate the scrollbar for full width modals
  &.mol-full-width-modal {
    padding-right: 0 !important;
  }

  &.mol-ccep-modal {
    padding-right: 0 !important;
  }
}

// kill the pseudo element added in bootstrap 4.1.3 for ie support
// because it kills our full-screen modal
.modal-dialog-centered {
  &::before {
    display: none !important;
    content: none !important;
  }
}

// Modal margin till -xl
// (not horizontally centered anymore! should be full screen)
//@include media-breakpoint-up(sm) {

.modal-dialog {
  margin: $modal-dialog-margin !important;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2)) !important;

  .mol-full-width-modal > & {
    margin: $mol-modal-dialog-margin-full-screen !important;
    min-height: calc(100% - (#{$mol-modal-dialog-margin-full-screen} * 2)) !important;
  }

  .mol-ccep-modal > & {
    margin: $mol-modal-dialog-margin-ccep !important;
    min-height: calc(100% - (#{$mol-modal-dialog-margin-ccep} * 2)) !important;
  }
}
//}

@include media-breakpoint-up(md) {
  .modal-dialog {
    .mol-full-width-modal > & {
      margin: $mol-modal-dialog-margin-full-screen !important; // $modal-dialog-margin !important;
      min-height: calc(100% - (#{$mol-modal-dialog-margin-full-screen} * 2)) !important; // calc(100% - (#{$modal-dialog-margin} * 2)) !important;
    }

    .mol-ccep-modal > & {
      margin: $mol-modal-dialog-margin-ccep !important;
      min-height: calc(100% - (#{$mol-modal-dialog-margin-ccep} * 2)) !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal-dialog {
    max-width: 940px !important;
    margin: $modal-dialog-margin auto !important; // horizontally centered

    &.mol-modal-sm {
      max-width: 500px !important;
    }

    .mol-full-width-modal > & {
      max-width: 940px !important;
      margin: $modal-dialog-margin auto !important; // horizontally centered
      min-height: calc(100% - (#{$modal-dialog-margin} * 2)) !important; // calc(100% - (#{$modal-dialog-margin} * 2)) !important;
    }

    .mol-ccep-modal > & {
      max-width: 940px !important;
      margin: $modal-dialog-margin auto !important; // horizontally centered
      min-height: calc(100% - (#{$modal-dialog-margin} * 2)) !important;
    }
  }
}

@include media-breakpoint-up(xl) {
  .modal-dialog {
    max-width: $modal-lg !important;

    &.mol-modal-sm {
      max-width: 500px !important;
    }
    //margin: $modal-dialog-margin-y-sm-up auto !important; // horizontally centered

    .mol-full-width-modal > & {
      max-width: 100% !important;
      margin: $modal-dialog-margin 70px !important; // full with / not centered
    }

    .mol-ccep-modal > & {
      width: 1300px !important;
      max-width: calc(100vw - (2px + #{$modal-dialog-margin} + #{$modal-dialog-margin})) !important;
    }
  }
}

// viewport modal iFrame
// fix the height to viewport height

.mol-viewport-modal-frame {
  border-radius: .2rem;
  width:100%;
  border:0;
  margin:0;
  box-sizing:border-box;
  // 100vh - modal-border top & bottom (2 * 1px) - modal-margin top & bottom (2 * 15px)
  height:calc(100vh - (2px + #{$modal-dialog-margin} + #{$modal-dialog-margin})); // !!! will be recalculatet via JS to match the actual window.height !!!
  max-height: 800px;

  .mol-modal-sm & {
    max-height: 600px;
  }

  .mol-ccep-modal & {
    height:calc(100vh - (2px + #{$mol-modal-dialog-margin-ccep} + #{$mol-modal-dialog-margin-ccep})); // !!! will be recalculatet via JS to match the actual window.height !!!
    max-height: none;

    @include media-breakpoint-up(lg) {
      height:calc(100vh - (2px + #{$modal-dialog-margin} + #{$modal-dialog-margin})); // !!! will be recalculatet via JS to match the actual window.height !!!
    }

    @include media-breakpoint-up(xl) {
      max-height: 900px;
    }
  }

}

.mol-viewport-modal-frame-content-container {
  height:calc(100vh - (2px + #{$modal-dialog-margin} + #{$modal-dialog-margin}));
  max-height: 800px;

  &.mol-sm-modal-content {
    max-height: 600px;
  }
}

.mol-ccep-viewport-modal-frame-content-container {
  height:calc(100vh - (2px + #{$mol-modal-dialog-margin-ccep} + #{$mol-modal-dialog-margin-ccep}));
  max-height: none;

  @include media-breakpoint-up(lg) {
    height:calc(100vh - (2px + #{$modal-dialog-margin} + #{$modal-dialog-margin})); // !!! will be recalculatet via JS to match the actual window.height !!!
    max-height: 900px;
  }

  @include media-breakpoint-up(xl) {
    max-height: 900px;
  }
}


.mol-viewport-modal-frame-content-container_scroll_section {
  overflow-y:auto;
  overflow-x:auto;
  -webkit-overflow-scrolling: touch; // momentum scroll on iOS
}
