/**
 * container
 */

.mol-category-bestseller-listing-container{
  margin-left: -15px !important;
  margin-top: -15px !important;
}

.mol-category-bestseller-listing-item-container{
  padding-left: 15px;
  padding-top: 15px;
  width: 100%;
}

.mol-shop-listing-container {
  margin-left: -15px !important;
  margin-top: -15px !important;
}

.mol-shop-listing-item-container {
  padding-left: 15px;
  padding-top: 15px;
  width: 100%;
}

.mol-category-bestseller-listing-item,
.mol-shop-listing-item {
  width: 100%;
  padding: 0 0;
  background-color: $white;
  box-shadow: 0 0 0 1px $gray-300, 0px 2px 2px 0 $gray-300;
  border-radius: $border-radius;
}

@include media-breakpoint-up(xl) {
  .mol-category-bestseller-listing-item,
  .mol-shop-listing-item {
    box-shadow: 0 0 0 1px $gray-300, 0px 2px 2px 0 $gray-300;
  }
}

@include media-breakpoint-up(md) {

  .mol-category-bestseller-listing-container {
    margin-left: -10px !important;
    margin-top: -12px !important;
  }

  .mol-category-bestseller-listing-item-container{
    padding-left: 10px;
    padding-top: 12px;
  }

  .mol-shop-listing-container {
    margin-left: -15px !important;
    margin-top: -15px !important;
  }

  .mol-shop-listing-item-container {
    padding-left: 15px;
    padding-top: 15px;
  }
}

@include media-breakpoint-up(lg) {
  .mol-category-bestseller-listing-container {
    margin-left: -10px !important;
    margin-top: -12px !important;
  }

  .mol-category-bestseller-listing-item-container{
    padding-left: 10px;
    padding-top: 12px;
  }

  .mol-shop-listing-container {
    margin-left: -15px !important;
    margin-top: -15px !important;
  }

  .mol-shop-listing-item-container {
    padding-left: 15px;
    padding-top: 15px;
  }
}

/**
 * category cards
 */

.mol-category-card-head,
.mol-shop-card-head {
  padding: 1rem 1rem;

  /*border-bottom: 1px solid transparent;

  &:hover {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-250;
    text-decoration: none;

    & + .mol-shop-card-head-divider,
    & + .mol-category-card-head-divider {
      opacity: .7;
      border-top:1px solid #f6f6f6;
    }
  }*/
}

@include media-breakpoint-up(lg) {
  .mol-category-card-head {
    padding: 1.3rem 1.7rem;
  }

  .mol-shop-card-head {
    padding: 1.3rem 2rem;
  }
}

.mol-category-card-head-name {
  font-size: $mol-bit-larger-font-size;
  font-weight: $font-weight-semi-bold;
}

@include media-breakpoint-up(md) {

  .mol-category-card-head-name {
    // truncate
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.mol-category-card-head-divider,
.mol-shop-card-head-divider {
  position: relative;
  //margin: 0 1rem;
  height: 1.5rem;
  border-top:1px solid $gray-250;
  background-color: transparent;
  background: linear-gradient(180deg, #f6f6f6 ,#FFFFFF 1.5rem) no-repeat;

  &:after {
    position:absolute;
    top: -1px;
    width: 100%;
    height: 1.6rem;
    background-color: transparent;
    content: " ";
    background: linear-gradient(90deg, #FFFFFF, rgba(255,255,255,0), #FFFFFF) no-repeat;
  }

}

.mol-category-card-products {
  padding:0 1rem;
}

@include media-breakpoint-up(sm) {
  .mol-category-card-products {
    padding:0 40px;
  }
}

@include media-breakpoint-up(md) {
  .mol-category-card-products {
    padding:0 25px;
  }
}

@include media-breakpoint-up(lg) {
  .mol-category-card-products {
    padding:0 55px;
  }
}

.mol-category-card-footer {
  padding:1rem 1rem;
  padding-top: 1.5rem;
  font-size: $mol-smaller-font-size;

  & > a {
    white-space: nowrap;
    font-size: $mol-small-font-size;
    //font-weight: $font-weight-semi-bold;
    color: $body-color;

    & > img {
      height: 9px;
      opacity: .8;
    }
  }
}

@include media-breakpoint-up(lg) {
  .mol-category-card-footer {
    padding:1.3rem 1.8rem;
    padding-top: 1.5rem;
    //margin-top: 1.2rem;
    //background-color: $gray-100;
  }
}

/**
 * shop cards only
 */

.mol-shop-card-head-name {
  font-size: $mol-bit-larger-font-size;
  color: $mol-blue-dark;
  font-weight: $font-weight-semi-bold;

  &:hover {
    text-decoration: underline;
  }
}

.mol-top-shop-card-head-name {
  font-size: $mol-bit-larger-font-size;
  color: $body-color;
}

.mol-shop-card-head-qty {
  color: $secondary;
  font-size: $small-font-size;
  text-decoration: none !important;
}

.mol-shop-card-main {

  padding-bottom: 1rem;

  & .mol-shop-card-categories-block {
    padding: 0 1rem !important;

    @include media-breakpoint-up(md) {
      border-right: 1px solid $mol-border-color-light;
    }

    & .mol-shop-card-categories-list {
      & a:not(:first-of-type){
        margin-top: .4rem;
      }

      & a:hover {
        text-decoration: none;

        & .mol-shop-card-categories-list-item-name {
          text-decoration: underline;
        }
      }

      @include media-breakpoint-up(sm) {
        max-width: calc(100% - 100px); // minus logo
      }

      @include media-breakpoint-up(lg) {
        padding-left: 3.5rem !important;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: calc(100% - 430px); // minus besteller products
    }
  }
}

@include media-breakpoint-up(lg) {

  .mol-shop-card-main {

    padding-bottom: 1.4rem;

    & .mol-shop-card-categories-block {
      padding-left: 2rem !important;
      padding-right: 2.5rem !important;
      border-right: 1px solid $mol-border-color-light;
    }
  }
}

.mol-shop-card-products-footer {
  padding: 1rem 1rem;
  padding-top: 1.5rem;
  padding-bottom: 0px;
  font-size: $mol-smaller-font-size;

  & a {
    //white-space: nowrap;
    font-size: $mol-small-font-size;
    font-weight: $font-weight-normal;
    color: $secondary;

    & img {
      height: 9px;
      opacity: .7;
    }
  }
}

@include media-breakpoint-up(md) {
  .mol-shop-card-products-footer {
    & a {
      white-space: nowrap;
    }
  }
}
