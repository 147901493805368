/**
 * container
 */
.mol-topic-container {
  width: 100%;
  //border: 1px solid $gray-250;
  //border-radius: $border-radius;
  //border: 2px solid $gray-200;
  border: 1px solid $gray-250;
  border-radius: $border-radius;
}


@include media-breakpoint-up(md) {
  .mol-topic-container {
    //border: 2px solid $gray-200;
    border: 1px solid $gray-300;
  }
}

@include media-breakpoint-up(xl) {
  .mol-topic-container {
    //max-width: 90%; //1000px;

    /*&.product-page-topic {
      max-width: 930px;
    }*/
  }
}

/**
 * ColorExpress
 */

 .mol-topic-item-colorexpress {
   position: relative;
   width: 100%;
   padding: 1rem 1.5rem;
   text-decoration: none;
   color: $body-color; //$mol-blue-dark;

   //background: linear-gradient(45deg, #f1f1f1, #fcfcfc 30%, #fcfcfc 70%, #fcfcfc 80%, #f7f7f7 100%);
   background: linear-gradient(45deg, #F3F3F3, #FCFCFC 30%);

   //background: radial-gradient(circle farthest-corner at 50% 55%, rgba(255,255,255, .3), rgba(255,255,255, .3) 30%, transparent 55%), linear-gradient(45deg, #e9e9e9, #fcfcfc 30%, #fff 70%, #fcfcfc 80%, #f5f5f5 100%);
   //background: linear-gradient(180deg, #E9E9E9, transparent 4px), radial-gradient(circle farthest-corner at 50% 55%, rgba(255,255,255, .8), rgba(255,255,255, .8) 30%, transparent 55%), linear-gradient(30deg, #E1ECF2, #FFF 35%, #FFF 50%, #DEEAF1 95%);
   //background: radial-gradient(circle farthest-corner at 55% 60%, rgba(255,255,255, .8), rgba(255,255,255, .8) 20%, transparent 45%), url('../../images/topics/start/structure_bg.png'), linear-gradient(45deg, #E1ECF2, #FFF 45%, #FFF 55%, #DEEAF1 95%);

   &:hover {
     color: darken($body-color, 15%);
     text-decoration: none;
     //background: radial-gradient(circle farthest-corner at 50% 55%, rgba(255,255,255, .8), rgba(255,255,255, .8) 30%, transparent 55%), linear-gradient(45deg, lighten(#E1ECF2, 2%), #FFF 45%, #FFF 55%, lighten(#DEEAF1, 2%) 95%);
     //background: linear-gradient(45deg, lighten(#E1ECF2, 3%), #FFF 45%, #FFF 55%, lighten(#DEEAF1, 3%) 95%); //linear-gradient(45deg, #F3F3F3, #FDFDFD 40%);
     //border: 1px solid $gray-400;
   }
 }

 @include media-breakpoint-up(sm) {
   .mol-topic-item-colorexpress {
     padding: 1rem 1.5rem;
     background: linear-gradient(45deg, #F3F3F3, #FCFCFC 30%);
     //background: radial-gradient(circle farthest-corner at 50% 55%, rgba(255,255,255, .3), rgba(255,255,255, .3) 30%, transparent 55%), linear-gradient(45deg, #e9e9e9, #fcfcfc 30%, #fff 50%, #fcfcfc 70%, #f5f5f5 100%);
     //background: linear-gradient(45deg, #f1f1f1, #fcfcfc 30%, #fcfcfc 50%, #fcfcfc 70%, #f7f7f7 100%);
   }
 }

 @include media-breakpoint-up(md) {
   .mol-topic-item-colorexpress {
     padding: 0 2rem;
     //background: linear-gradient(45deg, #f4f4f4, #fcfcfc 20%, #fcfcfc 55%, #fcfcfc 65%, #f7f7f7 95%);
     padding: 0 1rem;
     background:
       linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%),
       linear-gradient(75deg, #FFFFFF, #FFFFFF 70%, rgba(255, 255, 255, 0.1) 100%),
       url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 180px no-repeat;
       
     &.mol-topic-item-colorexpress-2 {
       padding: 0 1rem;
       background:
         linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%),
         linear-gradient(75deg, #FFFFFF, #FFFFFF 70%, rgba(255, 255, 255, 0.1) 100%),
         url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 180px no-repeat;
    }
   }
 }

 @include media-breakpoint-up(lg) {
   .mol-topic-item-colorexpress {
     height: auto; //120px;
     padding:0 2rem;
     //background: linear-gradient(45deg, #f4f4f4, #fcfcfc 20%, #fcfcfc 45%, #fcfcfc 55%, #f7f7f7 95%);
     background:
       linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%),
       linear-gradient(75deg, #FFFFFF, #FFFFFF 79%, rgba(255, 255, 255, 0) 100%),
       url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 200px no-repeat;

      &.mol-topic-item-colorexpress-2 {
        height: auto;
        background:
          linear-gradient(75deg, #f6f6f6, rgba(255, 255, 255, 0) 30%),
          linear-gradient(75deg, #FFFFFF, #FFFFFF 74%, rgba(255, 255, 255, 0) 100%),
          url("../../images/mol/templ/topics/caparol_farben/colorexpress_wirbel.png") right top/auto 200px no-repeat;
      }
    }
 }

 @include media-breakpoint-up(xl) {
   .mol-topic-item-colorexpress {
     padding:0 2rem;
   }
 }
