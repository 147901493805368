/**
 * Offcanvas
 */
.mol-offcanvas {
  position: absolute;
  visibility: hidden;
  opacity: 0;

  &.mol-offcanvas-open {
    visibility: visible;
    opacity: 1;
  }
}

.mol-offcanvas-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 90%;
  max-width: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; // momentum scroll on iOS
  background-color: $white;
  box-shadow: 0 .1rem .4rem #000;
  opacity: 1;
  z-index: $mol-zindex-offcanvas;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  -webkit-transform: translateX(-101%);
  transform: translateX(-101%);

  &.mol-offcanvas-from-right {
    right: 0;
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }

  &.mol-offcanvas-open {

    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}

// Kill the scroll on the body
.mol-offcanvas-backdrop-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mol-offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $modal-backdrop-bg;
  transition: opacity .3s;
  opacity: 0;
  z-index: $mol-zindex-offcanvas-backdrop;

  &.mol-offcanvas-backdrop-open {
    opacity: $modal-backdrop-opacity;

    .mol-ccep-color-card-offcanvas &,
    .mol-ccep-selected-color-offcanvas & {
      opacity: .5;
    }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.mol-offcanvas-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.mol-offcanvas-head, .mol-offcanvas-sub-head {
  background-color: $gray-200;
  padding-left: $mol-offcanvas-padding-x;
  padding-right: $mol-offcanvas-padding-x;
  padding-top: $mol-offcanvas-padding-y;
  padding-bottom: $mol-offcanvas-padding-y;
  border-bottom: 1px solid $gray-300;
  //box-shadow: 0 .1rem .4rem rgba($black, .15)
}

.mol-offcanvas-sub-head {
  border-bottom: 1px solid $gray-300;
  background-color: $gray-100;
}

.mol-offcanvas-body {
  background-color: $white;
  padding-bottom: $mol-offcanvas-padding-y;
}

.mol-offcanvas-item {
  display: block;
  color: $body-color;
  background-color: $white;
  padding: $mol-offcanvas-padding-y $mol-offcanvas-padding-x;

  & .mol-icon-container {
    width: 1.9rem;
    min-width: 1.9rem;
    margin-right: .5rem;
    line-height: 0;

    & > img {
      height: .8rem;
      opacity: .7;
    }
  }
}

.mol-offcanvas-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid $gray-300;
}

.mol-offcanvas-divider-dark {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 1px solid $gray-400;
}

/**
 * offcanvas adption for category nav Menu
 * which isn't an offcanvas at xl
 */
.mol-offcanvas-dialog.mol-cat-menu-dialog {
  left: 0;
}

// static at xl
@include media-breakpoint-up(xl) {

  .mol-offcanvas-dialog.mol-cat-menu-dialog {
    position: static !important;
    top: auto !important;
    bottom: auto !important;
    width: auto !important;
    max-width: none !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    //-webkit-overflow-scrolling: touch; // momentum scroll on iOS
    background-color: transparent !important;
    box-shadow: none !important;
    opacity: 1;
    z-index: auto !important;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: none !important;
    transform: none !important;

    & > .mol-offcanvas-body {
      padding-bottom: 0;
      background-color: transparent;

      & .mol-offcanvas-item {
        max-width: 220px;
        background-color: transparent;
      }
    }
  }

  .mol-offcanvas.mol-cat-menu {
    position: static !important;
    visibility: visible !important;
    width: 240px;
    opacity: 1 !important;

    &.mol-ccep-selected-color-offcanvas {
      width: 320px;
    }

    &.mol-cat-menu-slim {
      width: 210px;
      padding-right: 1rem;
    }

    & > .mol-offcanvas-backdrop {
      &:not(.mol-offcanvas-backdrop-open) {
        visibility: hidden;
      }
    }
  }
}

// items
// no x-padding at xl
@include media-breakpoint-up(xl) {
  .mol-cat-menu-dialog .mol-offcanvas-item {
    padding: $mol-offcanvas-padding-y 0;
  }

  .mol-cat-menu-dialog .mol-offcanvas-divider {
    height: 0;
    margin: 0;
    overflow: hidden;
    border-top: 1px solid $mol-border-color-light;
  }
}

// sub heads
// no xpadding and no background at xl
@include media-breakpoint-up(xl) {
  .mol-offcanvas-sub-head {
    margin-top: 4px;
    border-top: 1px solid $mol-border-color-light;
    border-bottom: 0;
    padding: $mol-offcanvas-padding-y 0;
    padding-top: 1.6rem !important;
    background-color: transparent;
  }
}
