/**
 * category nav
 */

.mol-category-nav-item {
  display: block;
  color: $body-color;
  padding-top: .3rem;
  padding-bottom: .3rem;
  line-height: 1.4;

  &:first-of-type {
    padding-top: 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: .22rem;
    padding-bottom: .22rem;

    &:first-of-type {
      padding-top: 0;
    }

    .mol-start-categories-box & {
      padding-top: .27rem;
      padding-bottom: .27rem;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }

  &.active {
    text-decoration: none;
    font-weight: $font-weight-semi-bold;
    //color: darken($body-color, 15%) !important;
  }

  &.current {
    text-decoration: underline;
    font-weight: $font-weight-bold;
    //color: darken($body-color, 15%) !important;
  }

  &.mol-level-1 {
    //color: $mol-blue-secondary;
    color: $primary;
    &:hover {
      color: darken($primary, 15%);
      //background-color: $gray-200;
    }

    &.mol-market-category {
      color: $mol-blue-secondary;
    }

    &.mol-result-shop-category {
      color: $primary;

      &:hover {
        color: darken($primary, 15%);
      }
    }
  }

  &.mol-level-2 {
    padding-left: 1.5rem;
    color: $body-color;

    &:hover {
      color: darken($body-color, 15%);
      //background-color: $gray-200;
    }

    &.mol-market-category {
      color: $mol-blue-dark;
    }
  }

  &.mol-level-3 {
    padding-left: 3rem;
    color: $primary; //$body-color;

    &:hover {
      color: darken($primary, 15%); //darken($body-color, 15%);
      //background-color: $gray-200;
    }
  }

  &:hover {
    //color: $body-color;
    text-decoration: underline;
    //background-color: $gray-200;

    /*& .mol-category-nav-item-arrow {
      opacity: .7;
    }*/
  }
}

@include media-breakpoint-up(xl) {
  .mol-category-nav-back-link {
    color: #666 !important; //$mol-blue-dark; //#777 !important; //#666 !important;
    font-size: $mol-small-font-size !important;
    //margin-left: -10px;
    padding-top: .15rem;
    padding-bottom: .15rem;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & .mol-category-nav-item-back-arrow {
      opacity: .4;
      //height: 10px;
      //width: 5px;
      //margin-right: 5px;
      margin-right: 6px;
      height: 9px;
      width: 5px;
    }

    &:hover {
      text-decoration: none;
      color: darken(#777, 15%) !important;

      & .mol-text-hover-underline {
        text-decoration: underline;
      }
    }

    /*& ~ .mol-category-nav-item:not(.mol-category-nav-back-link) {
      margin-left:11px;
    }*/
  }
}

/**
 * arrow
 */
.mol-category-nav-item-arrow {
  margin-right: .5rem;
  opacity: .5;
  height: 9px;
  width: 5px;

  /*.active &,
  .current & {
    opacity: .7;
  }*/
}

@include media-breakpoint-up(xl) {
  .mol-category-nav-item-arrow {
      margin-right: 6px;
      opacity: .4;
      height: 9px;
      width: 5px;
  }
}

/*
 * Offcanvas till XL
 */
.mol-offcanvas-dialog.mol-cat-menu-dialog {
  & .mol-category-nav-item
  {
    font-size: 1rem;
  }
}


@include media-breakpoint-up(xl) {
  .mol-offcanvas-dialog.mol-cat-menu-dialog {

    & .mol-category-nav-item
    {
      font-size: $mol-smaller-font-size;
    }

    & .mol-category-nav-item.mol-shop-start-page-category
    {
      font-size: $mol-smaller-font-size; //1rem;
      color: $primary;
      //padding-top: .5rem;
      //padding-bottom: .5rem;
      /*
      &:first-of-type {
        padding-top: 0;
      }*/
    }

    & .mol-category-nav-item.mol-market-category
    {
      font-size: $mol-smaller-font-size;
    }

    & .mol-category-nav-item.mol-brand-link
    {
      font-size: $mol-smaller-font-size;
    }
  }
}

/*
 * Content section navigator
 */
.mol-content-section-navigator {
  //border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-200;
  //font-size: $mol-smaller-font-size;
}
